/**
 * Enum representing names of predefined templates for More Games entries.
 * Provides human-readable names for different types of templates such as default, truckers, and mobile esports.
 */
export enum TemplateNames {
  default = 'Boombit template',
  truckers = 'Trucker template',
  mobileEsports = 'Mobile Esports template',
  mobileEsportsBitcoin = 'Mobile Esports: Earn Bitcoin template',
}
