import {Injectable} from '@angular/core';
import {Tab} from '../../shared/interface/ui/tabs/Tab';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {IMoreGamesForm} from '../interface/IMoreGamesForm';
import {MoreGamesFormSteps} from '../enum/IMoreGamesFormSteps';
import {mapRegionsToSelect} from 'src/app/shared/util/region/region.util';
import {Locations} from '../enum/Locations';
import {
  adUnitFormatMatchesFn,
  playableAndSquareMatchFn,
  videoAndSquareMatchFn
} from '../util/more-games-ad-units-format.validator';
import {
  campaignsRequired,
  creativesRequired,
  platformDefined,
  platformEnabled,
  platformForDestinationEnabled,
  platformForDestinationRequired
} from '../util/more-games-form.validator';
import {IMoreGamesDetails} from '../interface/IMoreGamesDetails';
import {AdUnitType} from 'src/app/shared/enum/AdUnitType';
import {AdUnitService} from 'src/app/ad-unit/service/ad-unit';
import {ICampaignInMoreGames} from 'src/app/campaign/interface/ICampaignInMoreGames';
import {IProduct} from 'src/app/product/interface/IProduct';
import {TemplateNames} from '../enum/TemplateNames';
import {getControlsForLocation, getProductsCampaignsControl} from '../util/more-games.util';

@Injectable({
  providedIn: 'root'
})
export class MoreGamesFormService {
  public readonly locations: { name: Locations, label: string }[] = [
    {name: Locations.TOP, label: 'Top'},
    {name: Locations.TOP_VIDEO, label: 'Top video'},
    {name: Locations.TOP_PLAYABLE, label: 'Top playable'},
    {name: Locations.MIDDLE, label: 'Middle'},
    {name: Locations.MIDDLE_VIDEO, label: 'Middle video'},
    {name: Locations.BOTTOM, label: 'Bottom'}
  ];

  constructor(private formBuilder: FormBuilder, private adUnitService: AdUnitService) {
  }

  public isStepValid(activeStep: Tab, formGroup: FormGroup<IMoreGamesForm>): boolean {
    switch (activeStep?.name as MoreGamesFormSteps) {
      case MoreGamesFormSteps.SELECT_SOURCE:
        return formGroup.controls.sourceProduct.valid;
      case MoreGamesFormSteps.CREATE_MORE_GAMES:
        return formGroup.controls.name.valid && formGroup.controls.region.valid
        && formGroup.controls.template.valid && formGroup.controls.platform.valid;
      case MoreGamesFormSteps.CHOOSE_DESTINATIONS:
        return this.isChooseDestinationsStepValid(formGroup);
    }
  }

  private isChooseDestinationsStepValid(formGroup: FormGroup<IMoreGamesForm>): boolean {
    const currentLocation = formGroup.get('location').value;
    const locationName: Locations = currentLocation?.name as Locations;
    return getControlsForLocation(formGroup, locationName).every(control => control.valid);
  }

  public updateStepsSubLabels(steps: Tab[], formGroup: FormGroup<IMoreGamesForm>): void {
    steps.forEach((step) => {
      step.subLabel = this.getSubLabel(step.name as MoreGamesFormSteps, formGroup);
      step.textBorderDark = !!step.subLabel;
    });
  }

  public getSubLabel(name: MoreGamesFormSteps, formGroup: FormGroup<IMoreGamesForm>): string {
    switch (name) {
      case MoreGamesFormSteps.SELECT_SOURCE:
        return formGroup.controls.sourceProduct.value?.name;
      case MoreGamesFormSteps.CREATE_MORE_GAMES:
        return formGroup.controls.name.value;
      case MoreGamesFormSteps.CHOOSE_DESTINATIONS:
        return this.getProductsSubLabel(formGroup);
    }
  }

  public getProductsSubLabel(formGroup: FormGroup<IMoreGamesForm>): string {
    let sum = 0;
    this.locations.forEach(location => {
      const control = getProductsCampaignsControl(formGroup, location.name);
      sum += control?.value?.length || 0;
    });
    return sum ? sum + (sum > 1 ? ' products' : ' product') : null;
  }

  private async initAllPromotedProductsCampaigns(initialValue: IMoreGamesDetails, formGroup: FormGroup<IMoreGamesForm>): Promise<void> {
    for (const location of this.getLocationNames()) {
      await this.initPromotedProductsCampaigns(location, initialValue, formGroup);
    }
  }

  private getLocationPromotedProducts(initialValue: IMoreGamesDetails, location: Locations): IProduct[] {
    switch (location) {
      case Locations.TOP:
        return initialValue?.topPanelPromotedProducts;
      case Locations.TOP_VIDEO:
        return initialValue?.topVideoPanelPromotedProducts;
      case Locations.MIDDLE:
        return initialValue?.middlePanelPromotedProducts;
      case Locations.MIDDLE_VIDEO:
        return initialValue?.middleVideoPanelPromotedProducts;
      case Locations.BOTTOM:
        return initialValue?.bottomPanelPromotedProducts;
      case Locations.TOP_PLAYABLE:
        return initialValue?.topPlayablePanelPromotedProducts;
      default:
        return undefined;
    }
  }

  private getLocationCampaigns(initialValue: IMoreGamesDetails, location: Locations): ICampaignInMoreGames[] {
    switch (location) {
      case Locations.TOP:
        return initialValue?.topPanelCampaigns;
      case Locations.TOP_VIDEO:
        return initialValue?.topVideoPanelCampaigns;
      case Locations.MIDDLE:
        return initialValue?.middlePanelCampaigns;
      case Locations.MIDDLE_VIDEO:
        return initialValue?.middleVideoPanelCampaigns;
      case Locations.BOTTOM:
        return initialValue?.bottomPanelCampaigns;
      case Locations.TOP_PLAYABLE:
        return initialValue?.topPlayablePanelCampaigns;
      default:
        return undefined;
    }
  }

  private async initPromotedProductsCampaigns(location: Locations, initialValue: IMoreGamesDetails, formGroup: FormGroup<IMoreGamesForm>): Promise<void> {
    const initialValueLocation = this.getLocationPromotedProducts(initialValue, location);
    const locationCampaigns = this.getLocationCampaigns(initialValue, location);

    if (initialValue && initialValueLocation) {
      const locationPromotedProductsCampaigns = initialValueLocation.map((product: IProduct) => {
        const campaignFromPanelCampaigns = locationCampaigns
          .find((campaign: ICampaignInMoreGames) => campaign.product.id === product.id);

        const campaign: ICampaignInMoreGames = {
          id: campaignFromPanelCampaigns.id,
          name: campaignFromPanelCampaigns.name,
          product: campaignFromPanelCampaigns.product,
          creatives: campaignFromPanelCampaigns.creatives,
          adUnits: campaignFromPanelCampaigns.adUnits,
          default: campaignFromPanelCampaigns.default,
        };

        return {
          product: product,
          campaign: campaign
        };
      });

      const locationFormControl = getProductsCampaignsControl(formGroup, location);
      locationFormControl.setValue(locationPromotedProductsCampaigns);
    }
  }

  public async initForm(initialValue: IMoreGamesDetails, formGroup: FormGroup): Promise<FormGroup<IMoreGamesForm>> {
    const moreGamesAdUnitFormats = (await this.adUnitService.getAdUnits())
      .find(adUnit => adUnit.type === AdUnitType.MORE_GAMES)?.adUnitFormats || [];

    formGroup = this.formBuilder.group({
      sourceProduct: [initialValue?.product || null, [Validators.required]],
      name: [initialValue?.name || '', Validators.required],
      platform: [null, [Validators.required, platformDefined, platformEnabled]],
      region: [mapRegionsToSelect(initialValue?.regions || []), Validators.required],
      template: [initialValue && initialValue.htmlTemplate
        ? {name: TemplateNames[initialValue.htmlTemplate], originalKey: initialValue.htmlTemplate}
        : null,
        Validators.required],
      location: [null, Validators.required],
      topPanelAdUnitFormatType: [initialValue && initialValue.TopAdUnitFormat
        ? {name: initialValue.TopAdUnitFormat, label: initialValue.TopAdUnitFormat}
        : null,
        Validators.required],
      middlePanelAdUnitFormatType: [initialValue && initialValue.MiddleAdUnitFormat
        ? {name: initialValue.MiddleAdUnitFormat, label: initialValue.MiddleAdUnitFormat}
        : null,
        Validators.required],
      bottomPanelAdUnitFormatType: [initialValue && initialValue.BottomAdUnitFormat
        ? {name: initialValue.BottomAdUnitFormat, label: initialValue.BottomAdUnitFormat}
        : null,
        Validators.required],
      topPanelText: [initialValue?.topPanelText.name || null, Validators.required],
      bottomPanelText: [initialValue?.bottomPanelText.name || null, Validators.required],
      middlePanelText: [initialValue?.middlePanelText.name || null, Validators.required],
      topPromotedProductsCampaigns: [[], [Validators.required,
        campaignsRequired, creativesRequired, platformForDestinationRequired,
        platformForDestinationEnabled, adUnitFormatMatchesFn(moreGamesAdUnitFormats, Locations.TOP)]],
      middlePromotedProductsCampaigns: [[], [Validators.required,
        campaignsRequired, creativesRequired, platformForDestinationRequired,
        platformForDestinationEnabled, adUnitFormatMatchesFn(moreGamesAdUnitFormats, Locations.MIDDLE)]],
      bottomPromotedProductsCampaigns: [[], [Validators.required,
        campaignsRequired, creativesRequired, platformForDestinationRequired,
        platformForDestinationEnabled, adUnitFormatMatchesFn(moreGamesAdUnitFormats, Locations.BOTTOM)]],
      topVideoPromotedProductsCampaigns: [[], [campaignsRequired, creativesRequired, platformForDestinationRequired,
        platformForDestinationEnabled, videoAndSquareMatchFn(moreGamesAdUnitFormats)]],
      middleVideoPromotedProductsCampaigns: [[], [campaignsRequired, creativesRequired, platformForDestinationRequired,
        platformForDestinationEnabled, videoAndSquareMatchFn(moreGamesAdUnitFormats)]],
      topVideoPanelText: [initialValue?.topVideoPanelText?.name || null],
      middleVideoPanelText: [initialValue?.middleVideoPanelText?.name || null],
      topPlayablePromotedProductsCampaigns: [[], [campaignsRequired, creativesRequired, platformForDestinationRequired,
        platformForDestinationEnabled, playableAndSquareMatchFn(moreGamesAdUnitFormats)]],
      topPlayablePanelText: [initialValue?.topPlayablePanelText?.name || null],
    });

    await this.initAllPromotedProductsCampaigns(initialValue, formGroup);
    return formGroup as FormGroup<IMoreGamesForm>;
  }

  public getLocationNames(): Locations[] {
    return this.locations.map(section => section.name);
  }

  public getLocation(location: Locations): { name: Locations, label: string } {
    return {...this.locations.find(section => section.name === location)};
  }
}
