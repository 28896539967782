<app-base-side-dialog [dialogRef]="dialogRef" [showBackButton]="!!backAction?.backActionDialogConfig" [backAction]="backAction">
  <app-spinner *ngIf="isLoading"></app-spinner>

  <div class="preview-content" *ngIf="campaign">
    <div class="text-light side-dialog--padding-horizontal preview-header">
      <p class="mat-body-2 color-dark-grey-fourth">{{"campaign.campaignPreview.campaignPreview" | translate}}</p>

      <div class="side-dialog-header-row">
        <h1>{{campaign.name}}</h1>
      </div>
      <div class="side-dialog-header-row">
        <p>
          {{"product.promotedProduct" | translate}}: {{campaign.product.name}}
        </p>
        <p>
          {{"campaign.campaignPreview.start" | translate}}: {{campaign.startDate ? (campaign.startDate | date:'dd/MM/YYYY') : 'not set'}}
        </p>
        <p>
          {{"campaign.campaignPreview.end" | translate}}: {{campaign.endDate ? (campaign.endDate | date:'dd/MM/YYYY') : 'not set'}}
        </p>

        <div *ngIf="campaign?.adUnits?.length" class="center-gap-10">
          <p>{{"adUnit.adUnits" | translate}}:</p>
          <app-ad-unit-icons [adUnitTypes]="adUnitTypes"></app-ad-unit-icons>
        </div>
        <div *ngIf="!campaign?.adUnits?.length"  class="center-gap-5">
          <p>{{"adUnit.adUnits" | translate}}:</p>
          <p class="text-14-txt-2-light">{{"adUnit.noAdUnits" | translate}}</p>
        </div>

        <p>
          {{"campaign.campaignPreview.campaignGUID" | translate}}: {{campaign.id}}
        </p>
      </div>
    </div>

    <hr class="separator separator--no-margin"/>
    <div class="side-dialog--padding-horizontal">
      @if (this.tabs.length > 0) {
        <div class="tabs-container">
        <app-tabs [isNarrow]="true" (tabChanged)="onTabChanged($event)" [activeTab]="selectedTab"
                  [tabs]="tabs"></app-tabs>
        </div>
      }
    </div>
    <div class="side-dialog--padding-horizontal preview-body--scrollable">
      @if(!this.selectedTab.name || (this.selectedTab?.name === CampaignPreviewTab.CREATIVES)) {

        <app-creatives-details (deleted)="onCreativeDeleted($event)"
                               [backDialogConfig]="getBackDialogConfig()"
                               [creatives]="campaign.creatives"
                               [dialogRef]="dialogRef">
        </app-creatives-details>
        <div class="preview-row--separated" *ngIf="campaign.creatives.length === 0">
          <p>{{"creative.noCreativesFound" | translate}}</p>
        </div>

      } @else if(this.selectedTab?.name === CampaignPreviewTab.ASSOCIATED_WATERFALLS) {

        <div class="waterfall-list">
          @if(campaign.waterfalls?.length > 0) {

            @for (waterfall of campaign.waterfalls; track waterfall.id; let idx = $index) {

              <p>{{idx + 1}}.&nbsp;{{waterfall?.name}}
                <fa-icon *ngIf="waterfall.id"
                         (click)="onWaterfallClicked(waterfall.id)"
                         class="color-primary cursor-pointer action-icon"
                         [appIconTooltip]="faEye" [icon]="faEye">
                </fa-icon></p>

            }
          } @else {

            <p class="text-16-txt-1-light">{{"campaign.campaignPreview.noWaterfalls" | translate}}</p>
          }
        </div>

      } @else {

        <div>
          @if(associatedMoreGames?.length > 0) {
            <div>
              @for (moreGames of associatedMoreGames; track moreGames.id; let idx = $index) {
                <p class="more-games-name">{{idx + 1}}.&nbsp;{{moreGames?.name}}
                  <fa-icon *ngIf="moreGames.id"
                           (click)="onMoreGamesClicked(moreGames.id)"
                           class="color-primary cursor-pointer action-icon"
                           [appIconTooltip]="faEye" [icon]="faEye">
                  </fa-icon></p>
                <p style="padding-left: 30px" class="text-14-txt-2-light">{{moreGames.sections}}</p>
              }
            </div>
          } @else {
            <p class="text-16-txt-1-light">{{"campaign.campaignPreview.noMoreGames" | translate}}</p>
          }
        </div>
      }

    </div>
    <div class="side-dialog-action-buttons side-dialog--padding-horizontal">
      <button (click)="onCloseClicked()" mat-stroked-button color="primary">
        <fa-icon [icon]="faXMark" class="close-icon-big"></fa-icon>
        {{"button.close" | translate}}
      </button>
      <button (click)="onDeleteCampaignClicked()" mat-flat-button color="primary">
        <fa-icon [icon]="faTrash" ></fa-icon>
        {{"campaign.deleteCampaign" | translate}}
      </button>
      <button (click)="onEditCampaignClicked()" mat-flat-button color="primary">
        <fa-icon [icon]="faEdit"></fa-icon>
        {{"campaign.editCampaign" | translate}}
      </button>
    </div>
  </div>
</app-base-side-dialog>
