import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NavigationEnd, Router} from '@angular/router';
import {filter} from 'rxjs/operators';
import {useLogRocket} from './shared/util/logrocket.util';
import LogRocket from 'logrocket';
import {AuthService} from './shared/service/auth/auth/auth.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public isLoginComponent: boolean;
  public sub: Subscription;

  constructor(private translate: TranslateService, private router: Router, private authService: AuthService) {
    translate.setDefaultLang('en');
    translate.use('en');
    this.initLogRocket();
  }

  private initLogRocket(): void {
    if (useLogRocket()) {
      LogRocket.init('taxgje/cp-bb',{
        network: {
          requestSanitizer: request => {
            return {
              ...request,
              headers: {
                ...request.headers,
                Authorization: null
              },
            };
          },
        },
      });
    }

    this.sub = this.authService.user.subscribe(user => {
      if (useLogRocket() && user) {
        LogRocket.identify(user?.id, {
          name: user?.name,
          email: user?.email
        });
      }
    });
  }

  public ngOnInit(): void {
    this.setIsLoginComponent();
    this.observeRouterEvents();
  }

  private observeRouterEvents(): void {
    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        this.setIsLoginComponent();
      });
  }

  private setIsLoginComponent(): void {
    this.isLoginComponent = this.router.url.startsWith('/login');
  }

  public ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }
}
