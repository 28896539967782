import {Component, Inject, OnInit} from '@angular/core';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {BACK_ACTION, DIALOG_DATA} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {IBackAction} from '../../../shared/interface/ui/dialog/IBackAction';
import {faArrowUpRightFromSquare, faEdit, faEye, faTrash, faXmark} from '@fortawesome/pro-light-svg-icons';
import {getSideDialogConfig} from '../../../shared/util/dialog/dialog-back-action.util';
import {getRegionsNameShort} from '../../../shared/util/region/region.util';
import {ProductDialogService} from '../../../product/service/product-dialog/product-dialog.service';
import {CampaignDialogService} from '../../../campaign/service/campaign-dialog/campaign-dialog.service';
import {IMoreGamesDetails} from '../../interface/IMoreGamesDetails';
import {MoreGamesService} from '../../service/more-games.service';
import {MoreGamesDialogService} from '../../service/more-games-dialog.service';
import {IProductSmallInfo} from '../../../product/interface/IProductSmallInfo';

@Component({
  selector: 'app-more-games-preview',
  templateUrl: './more-games-preview.component.html',
  styleUrls: ['./more-games-preview.component.scss']
})
export class MoreGamesPreviewComponent implements OnInit {
  public moreGames: IMoreGamesDetails;

  public faXMark = faXmark;
  public faTrash = faTrash;
  public faEdit = faEdit;
  public faEye = faEye;

  public getRegionsNameShort = getRegionsNameShort;

  public isLoading = false;
  protected readonly faArrowUpRightFromSquare = faArrowUpRightFromSquare;

  constructor(public dialogRef: DialogRef, @Inject(DIALOG_DATA) public data: { moreGamesId: string },
              @Inject(BACK_ACTION) public backAction: IBackAction, private moreGamesService: MoreGamesService,
              private moreGamesDialogService: MoreGamesDialogService, private productDialogService: ProductDialogService,
              private campaignDialogService: CampaignDialogService) {
  }

  ngOnInit(): void {
    this.initMoreGames();
  }

  private async initMoreGames(): Promise<void> {
    this.isLoading = true;
    try {
      this.moreGames = await this.moreGamesService.getMoreGamesByInternalId(this.data.moreGamesId);
    } finally {
      this.isLoading = false;
    }
  }

  public async onDeleteMoreGamesClicked(): Promise<void> {
    const dialogRef = await this.moreGamesDialogService.openMoreGamesDeleteDialog(this.moreGames.name, this.moreGames.id);
    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.dialogRef.close();
      }
    });
  }

  public onClose(): void {
    this.dialogRef.close();
  }

  public onEditMoreGamesClicked(): void {
    this.moreGamesDialogService.openMoreGamesEditDialog(this.moreGames.id,
      getSideDialogConfig(MoreGamesPreviewComponent, this.backAction, this.data));
    this.onClose();
  }

  public onProductPreviewClicked(id: string): void {
    this.productDialogService.openProductPreview(id,
      getSideDialogConfig(MoreGamesPreviewComponent, this.backAction, this.data));
    this.onClose();
  }

  public destinationsDefined(destinations: IProductSmallInfo[]): boolean {
    return destinations && destinations.length > 0;
  }

  public onCampaignPreviewClicked(id: string): void {
    this.campaignDialogService.openCampaignPreviewDialog(id,
      getSideDialogConfig(MoreGamesPreviewComponent, this.backAction, this.data));
    this.onClose();
  }
}
