import {IPromotedProductCampaignMG} from '../interface/IPromotedProductCampaignMG';
import {Locations} from '../enum/Locations';
import {AbstractControl, FormControl, FormGroup} from '@angular/forms';
import {IMoreGamesForm} from '../interface/IMoreGamesForm';
import {IBasicSelectOption} from '../../shared/interface/ui/form/IBasicSelectOption';

export function getControlsForLocation(formGroup: FormGroup<IMoreGamesForm>, location: Locations): AbstractControl[] {
  switch (location) {
    case Locations.TOP_VIDEO:
    case Locations.MIDDLE_VIDEO:
    case Locations.TOP_PLAYABLE:
      return [
        getTextControl(formGroup, location),
        getProductsCampaignsControl(formGroup, location)
      ];
      default:
      return [
        getAdUnitFormatControl(formGroup, location),
        getTextControl(formGroup, location),
        getProductsCampaignsControl(formGroup, location)
      ];
  }

}

export function getProductsCampaignsControl(formGroup: FormGroup<IMoreGamesForm>, location: Locations):
  FormControl<IPromotedProductCampaignMG[]> {
  switch (location) {
    case Locations.TOP:
      return formGroup.controls.topPromotedProductsCampaigns;
    case Locations.TOP_VIDEO:
      return formGroup.controls.topVideoPromotedProductsCampaigns;
    case Locations.MIDDLE:
      return formGroup.controls.middlePromotedProductsCampaigns;
    case Locations.MIDDLE_VIDEO:
      return formGroup.controls.middleVideoPromotedProductsCampaigns;
    case Locations.BOTTOM:
      return formGroup.controls.bottomPromotedProductsCampaigns;
    case Locations.TOP_PLAYABLE:
      return formGroup.controls.topPlayablePromotedProductsCampaigns;
  }
}

export function getAdUnitFormatControl(formGroup: FormGroup<IMoreGamesForm>, location: Locations): FormControl<IBasicSelectOption> {
  switch (location) {
    case Locations.TOP:
      return formGroup.controls.topPanelAdUnitFormatType;
    case Locations.MIDDLE:
      return formGroup.controls.middlePanelAdUnitFormatType;
    case Locations.BOTTOM:
      return formGroup.controls.bottomPanelAdUnitFormatType;
    default:
      return null;
  }
}

export function getTextControl(formGroup: FormGroup<IMoreGamesForm>, location: Locations): FormControl<string> {
  switch (location) {
    case Locations.TOP:
      return formGroup.controls.topPanelText;
    case Locations.TOP_VIDEO:
      return formGroup.controls.topVideoPanelText;
    case Locations.MIDDLE:
      return formGroup.controls.middlePanelText;
    case Locations.MIDDLE_VIDEO:
      return formGroup.controls.middleVideoPanelText;
    case Locations.BOTTOM:
      return formGroup.controls.bottomPanelText;
    case Locations.TOP_PLAYABLE:
      return formGroup.controls.topPlayablePanelText;
  }
}
