import {IAdUnitFormat} from '../../ad-unit/interface/IAdUnitFormat';
import {Locations} from '../enum/Locations';
import {FormControl, FormGroup, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ICampaignDetails} from '../../campaign/interface/ICampaignDetails';
import {ICreativeDetails} from '../../creative/interface/ICreativeDetails';
import {IMoreGamesForm} from '../interface/IMoreGamesForm';
import {MoreGamesAdFormat} from '../enum/MoreGamesAdFormat';
import {IBasicSelectOption} from '../../shared/interface/ui/form/IBasicSelectOption';
import {IPromotedProductCampaignMG} from '../interface/IPromotedProductCampaignMG';
import {AdUnitType} from '../../shared/enum/AdUnitType';

const MORE_GAMES_BANNER = 'moreGamesBannerFormat';
const MORE_GAMES_SQUARE = 'moreGamesSquareFormat';
const MORE_GAMES_VIDEO = 'moreGamesVideoFormat';
const MORE_GAMES_VIDEO_LANDSCAPE = 'moreGamesVideoLandscapeFormat';
const MORE_GAMES_PLAYABLE = 'moreGamesPlayableFormat';
export const campaignsWithFormatErrors = 'campaignsWithFormatErrors';
export const campaignsWithVideoFormatErrors = 'campaignsWithVideoFormatErrors';
export const campaignsWithSquareFormatErrors = 'campaignsWithSquareFormatErrors';
export const campaignsWithPlayableFormatErrors = 'campaignsWithPlayableFormatErrors';

export function playableAndSquareMatchFn(adUnitFormats: IAdUnitFormat[]): ValidatorFn {
  return (formArray: FormControl<IPromotedProductCampaignMG[]>) => playableAndSquareMatch(formArray, adUnitFormats);
}

// For sections with playables the validation is as follows:
// All campaigns should have moreGamesSquareFormat format and moreGamesPlayableFormat format.
export function playableAndSquareMatch(formArray: FormControl<IPromotedProductCampaignMG[]>,
                                    adUnitFormats: IAdUnitFormat[]): null | ValidationErrors {
  const destinations = formArray.value;
  const missingIds: string[] = [];

  destinations.forEach((destination, index) => {
    if (hasSquareMissing(destination?.campaign as ICampaignDetails, formArray, adUnitFormats) ||
      hasPlayableMissing(destination?.campaign as ICampaignDetails, formArray, adUnitFormats)) {
      missingIds.push(destination.campaign.id);
    }
  });


  if (missingIds.length === 0) {
    return null;
  }
  let res = {};
  if (missingIds.length > 0) {
    res = {adUnitPlayableMatches: true, [campaignsWithPlayableFormatErrors]: missingIds};
  }
  return res;
}

export function videoAndSquareMatchFn(adUnitFormats: IAdUnitFormat[]): ValidatorFn {
  return (formArray: FormControl<IPromotedProductCampaignMG[]>) => videoAndSquareMatch(formArray, adUnitFormats);
}

// For sections with videos the validation is as follows:
// First campaign in list should have both moreGamesSquareFormat and moreGamesVideoFormat format.
// The rest of the campaigns should have moreGamesSquareFormat format.
export function videoAndSquareMatch(formArray: FormControl<IPromotedProductCampaignMG[]>,
                                    adUnitFormats: IAdUnitFormat[]): null | ValidationErrors {
  const destinations = formArray.value;
  const videoAndSquareMissingIds: string[] = [];
  const squareMissingIds: string[] = [];

  destinations.forEach((destination, index) => {
    if (index === 0 && hasVideoAndSquareMissing(destination?.campaign as ICampaignDetails, formArray, adUnitFormats)) {
      videoAndSquareMissingIds.push(destination.campaign.id);
    } else if (index !== 0 && hasSquareMissing(destination?.campaign as ICampaignDetails, formArray, adUnitFormats)) {
      squareMissingIds.push(destination.campaign.id);
    }
  });


  if (squareMissingIds.length === 0 && videoAndSquareMissingIds.length === 0) {
    return null;
  }
  let res = {};
  if (squareMissingIds.length > 0) {
    res = {adUnitSquareMatches: true, [campaignsWithSquareFormatErrors]: squareMissingIds};
  }
  if (videoAndSquareMissingIds.length > 0) {
    res = {...res, adUnitVideoMatches: true, [campaignsWithVideoFormatErrors]: videoAndSquareMissingIds};
  }
  return res;
}


function hasVideoAndSquareMissing(campaign: ICampaignDetails, formArray: FormControl<IPromotedProductCampaignMG[]>,
                                adUnitFormats: IAdUnitFormat[]): boolean {
  const creatives = campaign?.creatives as ICreativeDetails[];
  if (!creatives || creatives?.length === 0) {
    return false;
  }

  if(!creatives.some(creative => creative.adUnit.type === AdUnitType.MORE_GAMES)) {
    return true;
  }

  const adUnitFormatIds = getAdUnitFormatIds(creatives);
  return missingBothVideoAdUnitFormat(adUnitFormatIds, adUnitFormats);
}

function hasSquareMissing(campaign: ICampaignDetails, formArray: FormControl<IPromotedProductCampaignMG[]>,
                                     adUnitFormats: IAdUnitFormat[]): boolean {
  const creatives = campaign?.creatives as ICreativeDetails[];
  if (!creatives || creatives?.length === 0) {
    return false;
  }

  if(!creatives.some(creative => creative.adUnit.type === AdUnitType.MORE_GAMES)) {
    return true;
  }

  const adUnitFormatIds = getAdUnitFormatIds(creatives);
  return missingSquareForVideoSection(adUnitFormatIds, adUnitFormats);
}

function hasPlayableMissing(campaign: ICampaignDetails, formArray: FormControl<IPromotedProductCampaignMG[]>,
                          adUnitFormats: IAdUnitFormat[]): boolean {
  const creatives = campaign?.creatives as ICreativeDetails[];
  if (!creatives || creatives?.length === 0) {
    return false;
  }

  if(!creatives.some(creative => creative.adUnit.type === AdUnitType.MORE_GAMES)) {
    return true;
  }

  const adUnitFormatIds = getAdUnitFormatIds(creatives);
  return missingPlayable(adUnitFormatIds, adUnitFormats);
}

function missingBothVideoAdUnitFormat(formatIds: string[], formats: IAdUnitFormat[]): boolean {
  return missingVideo(formatIds, formats) ||
    missingSquareForVideoSection(formatIds, formats);
}

function missingSquareForVideoSection(formatIds: string[], formats: IAdUnitFormat[]): boolean {
  return !formatIds.includes(getIdForMoreGamesSquare(formats));
}

function missingPlayable(formatIds: string[], formats: IAdUnitFormat[]): boolean {
  return !formatIds.includes(getIdForMoreGamesPlayable(formats));
}

function missingVideo(formatIds: string[], formats: IAdUnitFormat[]): boolean {
  return !(formatIds.includes(getIdForMoreGamesVideo(formats)) && formatIds.includes(getIdForMoreGamesLandscapeVideo(formats)));
}



export function adUnitFormatMatchesFn(adUnitFormats: IAdUnitFormat[], location: Locations): ValidatorFn {
  return (formArray: FormControl<IPromotedProductCampaignMG[]>) => adUnitFormatMatches(formArray, adUnitFormats, location);
}

// For sections without videos the validation is as follows:
// All campaigns should have formats that match the selected format for the section.
export function adUnitFormatMatches(formArray: FormControl<IPromotedProductCampaignMG[]>,
                                    adUnitFormats: IAdUnitFormat[], location: Locations): null | ValidationErrors {
  const destinations = formArray.value;
  let valid = true;
  const invalidCampaignsIds: string[] = [];
  destinations.forEach(destination => {
    if (hasCampaignFormatError(destination?.campaign as ICampaignDetails, formArray, adUnitFormats, location)) {
      valid = false;
      invalidCampaignsIds.push(destination.campaign.id);
    }
  });
  return valid ? null : {adUnitMatches: true, [campaignsWithFormatErrors]: invalidCampaignsIds};
}

function hasCampaignFormatError(campaign: ICampaignDetails, formArray: FormControl<IPromotedProductCampaignMG[]>,
                                adUnitFormats: IAdUnitFormat[], location: Locations): boolean {
  const creatives = campaign?.creatives as ICreativeDetails[];
  if (!creatives || creatives?.length === 0) {
    return false;
  }

  if(!creatives.some(creative => creative.adUnit.type === AdUnitType.MORE_GAMES)) {
    return true;
  }

  const adUnitFormatIds = getAdUnitFormatIds(creatives);
  const parent = formArray.parent as FormGroup<IMoreGamesForm>;
  const formatSelected = getFormatForLocation(parent, location);
  return missingAdUnitFormat(formatSelected, adUnitFormatIds, adUnitFormats);
}

function missingAdUnitFormat(formatSelectValue: MoreGamesAdFormat, formatIds: string[], formats: IAdUnitFormat[]): boolean {
  return missingBanner(formatSelectValue, formatIds, formats) ||
    missingSquare(formatSelectValue, formatIds, formats);
}

function missingSquare(formatSelectValue: MoreGamesAdFormat, formatIds: string[], formats: IAdUnitFormat[]): boolean {
  return formatSelectValue === MoreGamesAdFormat.SQUARE && !formatIds.includes(getIdForMoreGamesSquare(formats));
}

function missingBanner(formatSelectValue: MoreGamesAdFormat, formatIds: string[], formats: IAdUnitFormat[]): boolean {
  return formatSelectValue === MoreGamesAdFormat.BANNER && !formatIds.includes(getIdForMoreGamesBanner(formats));
}

function getAdUnitFormatIds(creatives: ICreativeDetails[]): string[] {
  return creatives.filter(creative => creative.adUnit?.type === AdUnitType.MORE_GAMES)
    .flatMap(creative => creative.assets?.flatMap(asset => asset.adUnitFormatIds)) || [];
}

function getFormatForLocation(formGroup: FormGroup<IMoreGamesForm>, location: Locations): MoreGamesAdFormat {
  let adUnitFormatForDestination: IBasicSelectOption;
  switch (location) {
    case Locations.TOP:
      adUnitFormatForDestination = formGroup.controls.topPanelAdUnitFormatType.value;
      break;
    case Locations.MIDDLE:
      adUnitFormatForDestination = formGroup.controls.middlePanelAdUnitFormatType.value;
      break;
    case Locations.BOTTOM:
      adUnitFormatForDestination = formGroup.controls.bottomPanelAdUnitFormatType.value;
      break;
  }
  return adUnitFormatForDestination?.name as MoreGamesAdFormat;
}


function getIdForMoreGamesBanner(adUnitFormats: IAdUnitFormat[]): string {
  return adUnitFormats.find(adUnitFormat => adUnitFormat.name === MORE_GAMES_BANNER)?.id;
}

function getIdForMoreGamesSquare(adUnitFormats: IAdUnitFormat[]): string {
  return adUnitFormats.find(adUnitFormat => adUnitFormat.name === MORE_GAMES_SQUARE)?.id;
}

function getIdForMoreGamesPlayable(adUnitFormats: IAdUnitFormat[]): string {
  return adUnitFormats.find(adUnitFormat => adUnitFormat.name === MORE_GAMES_PLAYABLE)?.id;
}

function getIdForMoreGamesVideo(adUnitFormats: IAdUnitFormat[]): string {
  return adUnitFormats.find(adUnitFormat => adUnitFormat.name === MORE_GAMES_VIDEO)?.id;
}

function getIdForMoreGamesLandscapeVideo(adUnitFormats: IAdUnitFormat[]): string {
  return adUnitFormats.find(adUnitFormat => adUnitFormat.name === MORE_GAMES_VIDEO_LANDSCAPE)?.id;
}
