<app-base-center-dialog [showCloseButton]="false" [dialogRef]="dialogRef">
  <div class="confirm-dialog">
    <h1 class="margin-0">{{'moreGames.moreGamesDeleteCsv.header' | translate}}</h1>
    <p>{{'moreGames.moreGamesDeleteCsv.description' | translate}}</p>
    <div class="file-selection-wrapper">
      <div class="dropzone-wrapper"
           appFileDnd (fileDropped)="onFileDropped($event)">
        <div class="dropzone">
          <input accept=".csv" type="file" #fileDropRef (change)="onFileSelected($event)"/>
          <div class="d-flex align-center">
            <p class="text-14-txt-2-semi-bold">{{'moreGames.moreGamesDeleteCsv.uploadTextPrefix' | translate}}</p>
            <button mat-button (click)="fileDropRef.click()">
              <span style="text-decoration: underline"
                    class="text-14-txt-2-semi-bold">{{'moreGames.moreGamesDeleteCsv.uploadTextSuffixButton' | translate}}</span>
            </button>
          </div>
          <p *ngIf="file" class="align-center-gap-10 padding-t-20">
            <fa-icon class="color-success"
                     [icon]="faCircleCheck">
            </fa-icon>
            {{'moreGames.moreGamesDeleteCsv.fileSelected' | translate}}:
            {{file?.name}}
          </p>
        </div>
      </div>
    </div>
    <div class="actions-buttons">
      <button (click)="onCancel()" mat-stroked-button color="primary">
        {{'button.cancel' | translate}}
      </button>
      <button [disabled]="isConfirmDisabled()" (click)="onConfirm()" mat-flat-button color="primary">
        {{'moreGames.moreGamesDeleteCsv.confirmButton' | translate}}
      </button>
      <mat-spinner *ngIf="loading" [diameter]="30"></mat-spinner>
    </div>
  </div>
</app-base-center-dialog>
