import {Component, ElementRef, ViewChild} from '@angular/core';
import {DialogRef} from '../../../shared/service/dialog/dialog/dialog-ref';
import {DialogService} from '../../../shared/service/dialog/dialog/dialog.service';
import {MetaDataService} from '../../../shared/service/file-utils/meta-data.service';
import {firstValueFrom} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {SnackbarService} from '../../../shared/service/snackbar/snackbar.service';
import {faArrowDownToLine, faCircleCheck} from '@fortawesome/pro-light-svg-icons';
import {Status} from '../../../shared/enum/Status';
import {MoreGamesCsvService} from '../../service/more-games-csv.service';

@Component({
  selector: 'app-more-games-batch-delete',
  templateUrl: './more-games-batch-delete.component.html',
  styleUrl: './more-games-batch-delete.component.scss'
})
export class MoreGamesBatchDeleteComponent {
  @ViewChild('fileDropRef') fileElementRef: ElementRef;
  public file: File;
  public loading = false;
  protected readonly faArrowDownToLine = faArrowDownToLine;
  protected readonly Status = Status;
  protected readonly faCircleCheck = faCircleCheck;

  constructor(public dialog: DialogService, public dialogRef: DialogRef, private metadataService: MetaDataService,
              private translateService: TranslateService, private snackbarService: SnackbarService,
              private csvUploadService: MoreGamesCsvService) {
  }

  public onCancel(): void {
    this.dialogRef.close();
  }

  public onConfirm(): void {
    this.deleteMoreGames();
  }

  public isConfirmDisabled(): boolean {
    return !this.file || this.loading;
  }

  public onFileDropped(files: FileList): void {
    this.handleFileSelected(files[0]);
  }

  public onFileSelected(event: any): void {
    this.handleFileSelected(event?.target?.files[0]);
  }

  private handleFileSelected(file: File): void {
    if (file && this.metadataService.isFileCsv(file)) {
      this.file = file;
      this.fileElementRef.nativeElement.value = '';
    } else {
      this.handleWrongFileFormat();
    }
  }

  public async handleWrongFileFormat(): Promise<void> {
    const errorMessage = await firstValueFrom(this.translateService.get('import.fileFormatError'));
    this.snackbarService.openErrorSnackbar(errorMessage);
  }

  public deleteMoreGames(): void {
    this.loading = true;
    this.csvUploadService.moreGamesDeleteCsv(this.file).then(async () => {
      const message = await firstValueFrom(this.translateService.get('moreGames.moreGamesDeleteCsv.deleteSuccess'));
      this.snackbarService.openSuccessSnackbar(message);
      this.dialogRef.close();
    }).finally(() => this.loading = false);
  }
}
