import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiGeneralService} from '../../shared/service/api/api-general.service';
import {IMoreGamesCSV} from '../interface/IMoreGamesCSV';
import { Observable} from 'rxjs';
import {RequestType} from '../../shared/enum/RequestType';

/**
 * Service for handling API requests related to More Games CSV data.
 * Provides methods for uploading and validating More Games CSV files.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiMoreGamesCsvService {

  /**
   * Constructor for ApiMoreGamesCsvService.
   * @param httpClient Angular's HttpClient for making HTTP requests.
   * @param apiGeneralService Service providing common functionality for API requests.
   */
  constructor(private httpClient: HttpClient, private apiGeneralService: ApiGeneralService) {
  }

  /**
   * Uploads More Games CSV data to the server.
   * @param moreGames More Games CSV data to be uploaded.
   * @returns Observable<void> indicating the success of the operation.
   */
  public uploadCsv(moreGames: IMoreGamesCSV[]): Observable<void> {
    const url = `CSV/ImportMoreGames`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.post,
      url,
      data: moreGames
    });
  }

  /**
   * Validates a More Games CSV file on the server.
   * @param file The More Games CSV file to be validated.
   * @returns Observable<IMoreGamesCSV[]> with the validated More Games CSV data.
   */
  public validateCsv(file: File): Observable<IMoreGamesCSV[]> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<any>(this.apiGeneralService.getUrl('CSV/ValidateMoreGames'), formData);
  }

  public moreGamesDeleteCsv(file: File): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.httpClient.post<any>(this.apiGeneralService.getUrl('CSV/DeleteMoreGames'), formData);
  }
}
