import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild
} from '@angular/core';
import {
  faArrowUpRightFromSquare,
  faCheck,
  faCircleInfo,
  faCorner,
  faFloppyDiskCircleArrowRight,
  faTrash, faTriangleExclamation, faWarning,
  faXmark
} from '@fortawesome/pro-light-svg-icons';
import {
  AbstractControl, FormArray,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {noWhitespaceValidator} from '../../../shared/util/form/validators/no-whitespace.validator';
import {hasError, hasRequiredError} from 'src/app/shared/util/form/form-utils';
import {IBasicSelectOption} from '../../../shared/interface/ui/form/IBasicSelectOption';
import {AdUnitService} from '../../../ad-unit/service/ad-unit';
import {IAdUnit} from '../../../ad-unit/interface/IAdUnit';
import {IAsset} from '../../../asset/interface/IAsset';
import {Status} from '../../../shared/enum/Status';
import {getResolutionLabel} from '../../../asset/util/resolution.util';
import {IResolution} from '../../../asset/interface/IResolution';
import {ExpandedDialogService} from '../../../shared/service/dialog/expanded-dialog/expanded-dialog.service';
import {AssetListSelectComponent} from '../../../asset/component/asset-list-select/asset-list-select.component';
import {firstValueFrom, Observable, of, Subject, Subscription, timer} from 'rxjs';
import {AssetService} from '../../../asset/service/asset.service';
import {CreativeService} from '../../service/creative.service';
import {ActivatedRoute, Router} from '@angular/router';
import {AssetLinkService} from '../../../asset/service/asset-link/asset-link.service';
import {ICreativeRaw} from '../../interface/ICreativeRaw';
import {BACK_ACTION} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {IBackAction} from '../../../shared/interface/ui/dialog/IBackAction';
import {ProductsService} from '../../../product/service/product/products.service';
import {ICanDeactivateFormCmp} from '../../../shared/interface/ui/form/ICanDeactivateFormCmp';
import {TranslateService} from '@ngx-translate/core';
import _ from 'lodash';
import {ICreativeDetails} from '../../interface/ICreativeDetails';
import {switchMap} from 'rxjs/operators';
import {IFormScrollableHandler} from '../../../shared/interface/ui/form/IFormScrollableHandler';
import {FormScrollableHandler} from '../../../shared/class/FormScrollableHandler';
import {IFormScrollable} from '../../../shared/interface/ui/form/IFormCmp';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {ProductEndpoints} from '../../../shared/enum/Endpoints';
import {ImageDataService} from '../../../shared/service/file-utils/image-data.service';
import {IAssetFileOutput} from '../../../asset/interface/IAssetFileOutput';
import {AssetControl, ICreativeFormGroup, SplitScreenSectionGroup} from '../../interface/ICreativeFormGroup';
import {AdUnitType} from '../../../shared/enum/AdUnitType';
import {CreativeFormUtilService} from '../../service/creative-form-util.service';
import {SnackbarService} from '../../../shared/service/snackbar/snackbar.service';
import {FileUploadService} from '../../../asset/service/file-upload.service';
import {v4 as uuidv4} from 'uuid';
import {CreativeFormStateService} from '../../service/creative-form-state.service';
import {CreativeFormInitService} from '../../service/creative-form-init.service';
import {CreativeControlsService} from '../../service/creative-controls.service';
import {CreativeRawService} from '../../service/creative-raw.service';
import {VideoFormatSimple} from '../../../asset/enum/VideoFormatSimple';

@Component({
  selector: 'app-creative-form',
  templateUrl: './creative-form.component.html',
  styleUrls: ['./creative-form.component.scss']
})
export class CreativeFormComponent implements OnInit, OnDestroy, ICanDeactivateFormCmp, AfterViewChecked, IFormScrollable {
  @ViewChild('formScrollable') formScrollable: ElementRef;
  private _hasScroll;

  @Input() public isDialog = false;
  @Input() public isEditMode = false;
  @Input() public initialValue: ICreativeDetails;

  public canDeactivateForm = false;

  public initialProductId: string;
  public formGroup: FormGroup<ICreativeFormGroup>;

  public faFloppyDiskCircleArrowRight = faFloppyDiskCircleArrowRight;
  public faArrowUpRightFromSquare = faArrowUpRightFromSquare;
  public faTrash = faTrash;
  public faCheck = faCheck;
  public faXMark = faXmark;
  public faCircleInfo = faCircleInfo;
  protected readonly faCorner = faCorner;


  public hasRequiredError: (control: AbstractControl) => boolean = hasRequiredError;
  public hasError: (control: AbstractControl, errorCode: string) => boolean = hasError;
  public getResolutionLabel: (resolution: IResolution, ratio: string) => string = getResolutionLabel;

  public adUnitOptions: (IBasicSelectOption & IAdUnit)[];
  public adUnits: IAdUnit[];
  public initialized = false;
  private subscriptions: Subscription[] = [];

  public promises: Promise<any>[] = [];

  public Status = Status;

  public isSubmitLoading = false;
  public isFormLoading = false;

  public closeDialogSubject = new Subject<boolean>();
  private formScrollableHandler: IFormScrollableHandler;
  protected readonly Validators = Validators;

// checksumsExisting - a map of checksums that already exist in the system, used to warn against adding the same file twice.
  private checksumsExisting: Set<string> = new Set<string>();


  constructor(private formBuilder: UntypedFormBuilder, private adUnitService: AdUnitService,
              private expandedDialogService: ExpandedDialogService, private assetService: AssetService,
              private creativeService: CreativeService,
              private router: Router, private assetLinkService: AssetLinkService,
              @Optional() @Inject(BACK_ACTION) public backAction: IBackAction, private productService: ProductsService,
              private translateService: TranslateService, public changeDetectorRef: ChangeDetectorRef,
              private route: ActivatedRoute, private loadingService: LoadingService,
              private imageDataService: ImageDataService,
              private creativeFormService: CreativeFormUtilService,
              private snackbarService: SnackbarService,
              private fileUploadService: FileUploadService,
              private creativeFormStateService: CreativeFormStateService,
              private creativeInitService: CreativeFormInitService,
              private creativeControlsService: CreativeControlsService,
              private creativeRawService: CreativeRawService) {
    this.initialProductId = this.route.snapshot.queryParamMap.get('productId');
    this.formScrollableHandler = new FormScrollableHandler(this);
  }

  public ngAfterViewChecked(): void {
    this.formScrollableHandler.setScrollStatus();
  }

  get isFormValid(): boolean {
    return this.formGroup?.valid;
  }

  public ngOnInit(): void {
    this.creativeFormStateService.initValue(this.initialValue);
    this.creativeFormStateService.initEditMode(this.isEditMode);
    this.observeLoading();
    this.initAdUnits().then(() => {
      this.initForm();
      if (this.initialValue?.adUnit) {
        this.initAdUnitForEdit();
      }
      this.initialized = true;
      this.observeForm();
    }).catch((error) => console.error(error));
  }

  public initForm(): void {
    this.displayErrorInDataIncorrect();
    this.prepareAssets();
    this.formGroup = this.formBuilder.group({
      name: [this.isEditMode ? this.initialValue.name : '', [Validators.required, noWhitespaceValidator],
        [this.creativeNameUnique.bind(this)]],
      product: [this.initialValue?.product ? this.initialValue.product : null, Validators.required],
      adUnit: [null, [Validators.required]],
      description: [this.initialValue?.description ? this.initialValue.description : ''],
      incentiveText: [this.initialValue?.incentiveText ? this.initialValue.incentiveText : ''],
      isStatic: [this.creativeInitService.getIsStaticInitial(this.adUnits)],
      useStaticForDefaultHtml: [this.creativeInitService.getUseStaticForDefaultHtml()],
      isSplitScreen: [!!this.creativeInitService.isSplitScreenEdit()],
      // isSplitScreenPortrait: [!!this.creativeInitService.isSplitScreenPortraitEdit(this.adUnits)],// [split_screen_landscape_disable] update after landscape support will we added on backend
      isSplitScreenPortrait: [{value: true, disabled: true}],
      // isSplitScreenLandscape: [!!this.creativeInitService.isSplitScreenLandscapeEdit(this.adUnits)], // [split_screen_landscape_disable] update after landscape support will we added on backend
      isSplitScreenLandscape: [{value: false, disabled: true}],
      isSplitScreenWith4Elements: [!!this.creativeInitService.isSplitScreenWith4ElementsEdit()],
      isSplitScreenWith2Elements: [!this.creativeInitService.isSplitScreenWith4ElementsEdit()],
      splitScreenSections: this.formBuilder.array([]),
      regularAssets: this.formBuilder.array([])
    });
    this.creativeFormStateService.initFormGroup(this.formGroup);

    if (this.initialProductId) {
      this.updateInitialProduct();
    }
  }

  private displayErrorInDataIncorrect(): void {
    if (this.isEditMode && this.initialValue.splitScreenProducts && ![0, 2, 4].includes(this.initialValue.splitScreenProducts.length)) {
      this.snackbarService.openErrorSnackbar('The number of products for split screen is incorrect', 5000);
    }
  }

  private prepareAssets(): void {
    const frontendIds = this.creativeInitService.setFrontendIds();
    this.creativeControlsService.markAssetsAsValidFormat(frontendIds);
  }

  private async updateInitialProduct(): Promise<void> {
    this.productService.getProductByInternalId(this.initialProductId)
      .then((product) => this.formGroup.controls.product.setValue(product))
      .catch(() => this.formGroup.controls.product.setValue(null));
  }

  public async initAdUnits(): Promise<void> {
    this.adUnits = await this.adUnitService.getAdUnitsFromSubject();
    this.adUnitOptions = this.adUnits.map(el => ({...el, label: el.name}));
  }

  private initAdUnitForEdit(): void {
    const adUnit: IBasicSelectOption & IAdUnit = this.adUnitOptions.find(adUnitOption => adUnitOption.id === this.initialValue.adUnit.id);
    this.formGroup.get('adUnit').setValue(adUnit);
    if (this.initialValue.assets) {
      this.creativeControlsService.initEditAssetsControls(adUnit as unknown as IAdUnit);
    } else {
      this.creativeControlsService.setNewAssetsControls(adUnit as unknown as IAdUnit);
    }
  }

  private observeForm(): void {
    this.observeAdUnitChanges();
    this.observeProductChanges();
    this.observeIsStaticChanges();
    this.observeIsSplitScreen();
    this.observeSplitScreenToggles();
    this.observeDefaultHtmlChanges();
  }

  public observeAdUnitChanges(): void {
    this.formGroup.get('adUnit').valueChanges.subscribe((value) => {
      this.formGroup.controls.isSplitScreen.setValue(false, {emitEvent: false});
      this.creativeControlsService.setNewAssetsControls(value as IAdUnit);
    });
  }

  private observeIsStaticChanges(): void {
    this.formGroup.controls.isStatic.valueChanges.subscribe((value) => {
      this.formGroup.controls.useStaticForDefaultHtml.setValue(value);
      if (value) {
        this.formGroup.controls.isSplitScreen.setValue(false);
      } else {
        this.creativeControlsService.setNewAssetsControls(this.formGroup.controls.adUnit.value);
      }
    });
  }

  private observeIsSplitScreen(): void {
    this.formGroup.controls.isSplitScreen.valueChanges.subscribe((value) => {
      if (value) {
        this.formGroup.controls.isStatic.setValue(false, {emitEvent: false});
        this.formGroup.controls.useStaticForDefaultHtml.setValue(false, {emitEvent: false});

        // this.formGroup.controls.isSplitScreenPortrait.setValue(true, {emitEvent: false}); // [split_screen_landscape_disable] update after landscape support will we added on backend
        // this.formGroup.controls.isSplitScreenLandscape.setValue(true, {emitEvent: false}); // [split_screen_landscape_disable] update after landscape support will we added on backend
        this.formGroup.controls.isSplitScreenWith4Elements.setValue(true, {emitEvent: false});
        this.formGroup.controls.isSplitScreenWith2Elements.setValue(false, {emitEvent: false});

        this.formGroup.controls.product.removeValidators(Validators.required);
        this.formGroup.controls.product.disable({emitEvent: false});
        this.formGroup.controls.splitScreenSections.enable({emitEvent: false});
        this.formGroup.controls.regularAssets.disable({emitEvent: false});
      } else {
        this.formGroup.controls.splitScreenSections.disable({emitEvent: false});
        this.formGroup.controls.product.addValidators(Validators.required);
        this.formGroup.controls.product.enable({emitEvent: false});
        this.formGroup.controls.splitScreenSections.disable({emitEvent: false});
        this.formGroup.controls.regularAssets.enable({emitEvent: false});
      }
      this.creativeControlsService.setNewAssetsControls(this.formGroup.controls.adUnit.value);
    });
  }

  private observeSplitScreenToggles(): void {
    this.formGroup.controls.isSplitScreenWith4Elements.valueChanges.subscribe((value) => {
      this.formGroup.controls.isSplitScreenWith2Elements.setValue(!value, {emitEvent: false});
      this.creativeControlsService.setNewAssetsControls(this.formGroup.controls.adUnit.value);
    });

    this.formGroup.controls.isSplitScreenWith2Elements.valueChanges.subscribe((value) => {
      this.formGroup.controls.isSplitScreenWith4Elements.setValue(!value, {emitEvent: false});
      this.creativeControlsService.setNewAssetsControls(this.formGroup.controls.adUnit.value);
    });

    this.formGroup.controls.isSplitScreenPortrait.valueChanges.subscribe((value) => {
      if (!value) {
        this.hideOrientationControls('portrait');
      } else {
        this.creativeControlsService.addOrientationControls('portrait');
      }
    });
    this.formGroup.controls.isSplitScreenLandscape.valueChanges.subscribe((value) => {
      if (!value) {
        this.hideOrientationControls('landscape');
      } else {
        this.creativeControlsService.addOrientationControls('landscape');
      }
    });
  }


  private hideOrientationControls(orientation: 'landscape' | 'portrait'): void {
    this.formGroup.controls.splitScreenSections.controls.map((section) => {
      const indicesToRemove: number[] = [];
      section.controls.assetControls.controls.forEach((control, index) => {
        const shouldRemove = orientation === 'landscape' ? control.controls.isLandscape.value :
          !control.controls.isLandscape.value;
        if (shouldRemove) {
          indicesToRemove.push(index);
        }
      });
      // Sort the indices in descending order to avoid index shifting
      indicesToRemove.sort((a, b) => b - a).forEach(index => {
        section.controls.assetControls.removeAt(index);
      });
    });
  }

  private observeDefaultHtmlChanges(): void {
    this.formGroup.controls.useStaticForDefaultHtml.valueChanges.subscribe(() => {
      this.creativeControlsService.setNewAssetsControls(this.formGroup.controls.adUnit.value);
    });
  }

  public observeProductChanges(): void {
    this.formGroup.get('product').valueChanges.subscribe(() => {
      this.creativeControlsService.setNewAssetsControls(this.formGroup.value.adUnit as IAdUnit);
    });
  }

  private observeLoading(): void {
    const subscription = this.loadingService.loadingEndpointsObs()
      .subscribe((loadingState) => {
        this.isFormLoading = this.loadingService.areEndpointsLoading(this._loadingEndpointNames(), loadingState);
      });
    this.subscriptions.push(subscription);

    const sub2 = this.creativeRawService.loadingSubject.subscribe((value) => {
      this.isSubmitLoading = value;
    });
    this.subscriptions.push(sub2);
  }

  public onCancelClicked(): void {
    this.closeForm(false);
  }

  private closeForm(omitConfirmation: boolean): void {
    if (this.isDialog) {
      this.closeDialogSubject.next(omitConfirmation);
    } else {
      this.router.navigate(['/creative/list']);
    }
  }

  public async onSubmitClicked(): Promise<void> {
    this.isSubmitLoading = true;
    if (this.isEditMode) {
      this.updateCreative();
    } else {
      this.createCreative();
    }
  }

  public isSubmitDisabled(): boolean {
    return !this.isFormValid || this.formGroup?.pending || this.isSubmitLoading;
  }

  public isPendingButonVisible(): boolean {
    return this.formGroup?.pending;
  }

  private async createCreative(): Promise<void> {
    const creativeRaw: ICreativeRaw = await this.creativeRawService.getCreativeRaw();

    this.creativeService.addCreative(creativeRaw).then(async () => {
      const message = await firstValueFrom(this.translateService.get('creative.creativeCreatedMessage'));
      this.snackbarService.openSuccessSnackbar(message);
      this.canDeactivateForm = true;
      this.closeForm(true);
    })
      .finally(() => this.isSubmitLoading = false);
  }

  private async updateCreative(): Promise<void> {
    const creativeRaw: ICreativeRaw = await this.creativeRawService.getCreativeRaw();
    creativeRaw.id = this.initialValue.id;

    this.creativeService.editCreative(creativeRaw).then(async () => {
      const message = await firstValueFrom(this.translateService.get('creative.creativeEditedMessage'));
      this.snackbarService.openSuccessSnackbar(message);
      this.closeForm(true);
    }).finally(() => this.isSubmitLoading = false);
  }

  public onAssetDeleteForm(assetGroup: FormGroup<AssetControl>): void {
    assetGroup.controls.asset.setValue(null);
    assetGroup.controls.file.setValue(null);
    assetGroup.updateValueAndValidity({emitEvent: false});
    this.triggerAssetsValidation(assetGroup);
  }

  public async onFileSelectedForm(data: IAssetFileOutput, assetGroup: FormGroup<AssetControl>): Promise<void> {
    const oldAssetControl = _.cloneDeep(assetGroup.controls.asset.value);
    assetGroup.controls.file.setValue(data.file);
    data.asset.frontendId = uuidv4();
    assetGroup.controls.asset.setValue(data.asset);
    await this.setRandomNameForAsset(assetGroup);
    await this.validateChecksum(data.asset.checksum);
    this.triggerAssetsValidation(assetGroup);
    this.handleAssetUpdatedMsg(oldAssetControl, assetGroup.controls.asset);
  }

  private handleAssetUpdatedMsg(oldAsset: IAsset, newAssetControl: FormControl<IAsset>): void {
    if (this.shouldShowSwappedMsg(oldAsset, newAssetControl)) {
      this.showAssetSwappedMsg();
    }
  }

  private shouldShowSwappedMsg(oldAsset: IAsset, newAssetControl: FormControl<IAsset>): boolean {
    return oldAsset && !(newAssetControl.invalid) && this.isEditMode &&
      !this.creativeFormService.isSameAsset(oldAsset, newAssetControl.value);
  }

  private showAssetSwappedMsg(): void {
    this.translateService.get('creative.creativeForm.assetSwappedMessage').subscribe((message) =>
      this.snackbarService.openSuccessSnackbar(message));
  }

  private async setRandomNameForAsset(assetGroup: FormGroup<AssetControl>): Promise<void> {
    if (!this.fileUploadService.canUploadUsingPresignedUrls()) {
      assetGroup.controls.asset.value.name = await this.getRandomNameForAsset(assetGroup.controls.asset.value.format);
    }
  }

  public async validateChecksum(checksum: string): Promise<void> {
    if (this.checksumsExisting.has(checksum)) {
      return;
    } else {
      try {
        const checksumExists = await this.assetService.checksumExists(checksum);
        if (checksumExists) {
          this.checksumsExisting.add(checksum);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  public checksumExists(asset: IAsset): boolean {
    if (!asset?.checksum) {
      return false;
    } else {
      return this.checksumsExisting.has(asset?.checksum);
    }
  }

  private async getRandomNameForAsset(assetExtension: string): Promise<string> {
    const assetNamePromise = this.assetService.getRandomAssetName(30);
    this.promises.push(assetNamePromise);
    return await assetNamePromise + '.' + assetExtension;
  }

  public onOpenAssetLibraryClickedForm(assetGroup: FormGroup<AssetControl>, isSplit: boolean): void {
    const component = this.expandedDialogService.open(AssetListSelectComponent);
    component.instance.adUnitId = assetGroup.controls.adUnitId.value;
    if (isSplit) {
      component.instance.product = (assetGroup.parent.parent as FormGroup<SplitScreenSectionGroup>).value.product;
    } else {
      component.instance.product = this.formGroup.controls.product.value;
    }
    component.instance.adUnitFormatId = assetGroup.controls.adUnitFormat.value.id;
    component.instance.adUnitFormatName = assetGroup.controls.adUnitFormat.value.name;
    const subscription = component.instance.assetSelected.subscribe((asset) => {
      const oldAssetControl = _.cloneDeep(assetGroup.controls.asset.value);
      asset.frontendId = uuidv4();
      this.creativeControlsService.markAssetAsValidFormat(asset.frontendId);
      assetGroup.controls.asset.setValue(asset);
      assetGroup.updateValueAndValidity({emitEvent: false});
      this.triggerAssetsValidation(assetGroup);
      this.handleAssetUpdatedMsg(oldAssetControl, assetGroup.controls.asset);
    });
    this.subscriptions.push(subscription);
  }

  public creativeNameUnique(control: AbstractControl): Observable<ValidationErrors | null> {
    if (this.initialValue?.name && this.initialValue.name === control.value) {
      return of(null);
    } else {
      return timer(1000).pipe(switchMap(async () => {
        const creativeNameExists: boolean = await this.creativeService.validateCreativeName(control.value);
        return creativeNameExists ? {creativeNameExists: true} : null;
      }));
    }
  }

  private triggerAssetsValidation(omitGroup: FormGroup<AssetControl>): void {
    if (this.isSplitScreen()) {
      this.triggerSplitScreenAssetsValidation(omitGroup);
    } else {
      this.triggerRegularAssetsValidation(omitGroup);
    }
  }

  private triggerSplitScreenAssetsValidation(omitGroup: FormGroup<AssetControl>): void {
    const splitScreenSections = this.formGroup.controls.splitScreenSections as FormArray<FormGroup<SplitScreenSectionGroup>>;
    splitScreenSections.controls.forEach(section => {
      section.controls.assetControls.controls.forEach(group => {
        if (group !== omitGroup) {
          group.controls.asset.updateValueAndValidity({emitEvent: false});
        }
      });
    });
  }

  private triggerRegularAssetsValidation(omitGroup: FormGroup<AssetControl>): void {
    const parentArray = this.formGroup.controls.regularAssets;
    parentArray.controls.forEach(group => {
      if (group !== omitGroup) {
        group.controls.asset.updateValueAndValidity({emitEvent: false});
      }
    });
  }

  public isMoreGamesSelected(): boolean {
    return this.formGroup.controls.adUnit.value?.type === AdUnitType.MORE_GAMES;
  }

  public isRewardedOrInterstitial(): boolean {
    return this.creativeControlsService.isRewardedOrInterstitial();
  }

  public isStatic(): boolean {
    return this.formGroup.controls.isStatic.value;
  }

  public isSplitScreen(): boolean {
    return this.formGroup.controls.isSplitScreen.value;
  }

  public get hasScroll(): boolean {
    return this._hasScroll;
  }

  public set hasScroll(value: boolean) {
    this._hasScroll = value;
  }

  private _loadingEndpointNames(): string[] {
    return [ProductEndpoints.getProductByInternalId(this.initialProductId)];
  }

  public get columnsCount(): number {
    const adUnitName = this.formGroup?.controls?.adUnit?.value?.name;
    return this.creativeFormService.getColumnsCount(adUnitName);
  }

  public assetGroupType(assetGroup: any): FormGroup<AssetControl> {
    return assetGroup as FormGroup<AssetControl>;
  }

  public getStatus(assetGroup: FormGroup<AssetControl>): Status {
    return this.creativeFormService.getStatus(assetGroup);
  }

  public ngOnDestroy(): void {
    this.creativeControlsService.destroy();
    this.creativeRawService.destroy();
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  protected readonly faWarning = faWarning;
  protected readonly faTriangleExclamation = faTriangleExclamation;
}
