import {Injectable} from '@angular/core';
import {RequestType} from 'src/app/shared/enum/RequestType';
import {IAsset} from 'src/app/asset/interface/IAsset';
import {ApiGeneralService} from '../../shared/service/api/api-general.service';
import {IAdUnitFormat} from '../../ad-unit/interface/IAdUnitFormat';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {IAssetRaw} from '../interface/IAssetRaw';
import {IAssetDetails} from '../interface/IAssetDetails';
import {QueryService} from '../../shared/service/query.service';
import {AssetList} from '../interface/AssetList';
import {map} from 'rxjs/operators';
import {AssetCreateBulkRaw} from '../interface/AssetCreateBulkRaw';
import {PresignedUrlPayload} from '../interface/PresignedUrlPayload';
import {PresignedUrlResponse} from '../interface/PresignedUrlResponse';

/**
 * Injectable service for handling API requests related to assets.
 * This service provides methods for interacting with the backend API to perform CRUD operations on assets.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiAssetService {

  /**
   * Constructor for ApiAssetService.
   * @param apiGeneralService The service for sending general API requests.
   * @param httpClient The HTTP client for making HTTP requests.
   * @param queryService The service for constructing query parameters.
   */
  constructor(
    private apiGeneralService: ApiGeneralService, private httpClient: HttpClient, private queryService: QueryService
  ) {
  }

  /**
   * Retrieves a random asset name of a specified length.
   * @param nameLength The length of the random asset name.
   * @returns An observable string representing the random asset name.
   */
  public getRandomAssetName(nameLength: number): Observable<string> {
    let url = 'Assets/GetRandomName/';
    url += nameLength;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }

  public getRandomAssetNames(length: number, numberOfStrings: number): Observable<string[]> {
    const url = `Assets/GetRandomNames/${numberOfStrings}/${length}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }


  /**
   * Checks if an original name for an asset already exists.
   * @param name The name to check.
   * @returns An observable indicating whether the original name exists.
   */
  public checkOriginalName(name: string): Observable<boolean> {
    const url = `Assets/CheckOriginalName/${encodeURIComponent(name)}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url
    });
  }

  /**
   * Checks if the checksum already exists.
   * @param checksum Checksum to check.
   */
  public checksumExists(checksum: string): Observable<boolean> {
    const url = `Assets/ChecksumExist/${encodeURIComponent(checksum)}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url
    });
  }

  public checksumsExists(names: string[]): Observable<{ [key: string]: boolean }> {
    const url = `Assets/ChecksumsExist`;
    let params = new HttpParams();
    names.forEach((name) => {
      params = params.append('Checksums', name);
    });
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
      params
    });
  }

  public checkOriginalNames(names: string[]): Observable<{ [key: string]: boolean }> {
    const url = `Assets/CheckOriginalNames`;
    let params = new HttpParams();
    names.forEach((name) => {
      params = params.append('Names', name);
    });
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
      params
    });
  }

  /**
   * Updates an existing asset.
   * @param asset The asset to update.
   * @returns An observable indicating the success of the operation.
   */
  public updateAsset(asset: IAssetRaw): Observable<void> {
    const url = 'Assets';
    return this.apiGeneralService.sendRequest({
      method: RequestType.put,
      url,
      data: asset
    });
  }

  /**
   * Creates a new asset.
   * @param productId The ID of the product associated with the asset.
   * @param asset The asset to create.
   * @returns An observable representing the newly created asset.
   */
  public createAsset(productId: string, asset: IAssetRaw): Observable<IAsset> {
    const url = `Assets/${productId}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.post,
      url,
      data: asset
    });
  }

  public createAssets(assetsBulk: AssetCreateBulkRaw[]): Observable<IAsset> {
    const url = `Assets/bulk`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.post,
      url,
      data: assetsBulk
    });
  }

  /**
   * Uploads a file associated with an asset.
   * @param file The file to upload.
   * @param boombitId The BoomBit ID associated with the asset.
   * @returns An observable indicating the success of the upload operation.
   */
  public uploadFile(file: File, boombitId: string): Observable<void> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    const params = new HttpParams().set('boomBitId', boombitId);
    return this.httpClient.post<any>(this.apiGeneralService.getUrl('Assets/Upload'), formData, {params});
  }

  /**
   * Retrieves a paginated list of assets based on the provided query parameters.
   * @param query The query parameters for filtering and pagination.
   * @returns An observable representing the paginated list of assets.
   */
  public getAssetsPaginated(query: any): Observable<AssetList> {
    const params = this.queryService.getHttpParams(query);
    const url = 'Assets/PaginatedAndFiltered';

    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
      params
    });
  }

  /**
   * Retrieves a list of asset names.
   * @returns An observable representing the list of asset names.
   */
  public getAssetNames(): Observable<string[]> {
    const url = 'Assets/Names';
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url
    }).pipe(map(value => value.filter(el => !!el)));
  }

  /**
   * Retrieves asset details by ID.
   * @param assetId The ID of the asset to retrieve.
   * @returns An observable representing the asset details.
   */
  public getAssetById(assetId: string): Observable<IAssetDetails> {
    const url = `Assets/GetById/${assetId}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }

  /**
   * Deletes an asset by ID.
   * @param assetId The ID of the asset to delete.
   * @returns An observable indicating the success of the deletion operation.
   */
  public deleteAsset(assetId: string): Observable<void> {
    const url = `Assets/${assetId}`;

    return this.apiGeneralService.sendRequest({
      method: RequestType.delete,
      url,
    });
  }

  /**
   * Retrieves ad unit formats associated with an asset.
   * @param asset The asset to match ad unit formats to.
   * @returns An observable representing the list of ad unit formats.
   */
  public getAssetAdUnitFormats(asset: IAsset): Observable<IAdUnitFormat[]> {
    const url = 'Assets/MatchAssetToAdUnitFormats/';

    return this.apiGeneralService.sendRequest({
      method: RequestType.post,
      url,
      data: asset
    });
  }

  public getAssetsAdUnitFormats(assets: IAsset[]): Observable<{
    adUnitFormats: IAdUnitFormat[],
    assetId: string,
    errors: string
  }[]> {
    const url = 'Assets/MatchAssetsToAdUnitFormats';

    return this.apiGeneralService.sendRequest({
      method: RequestType.post,
      url,
      data: assets
    });
  }

  public generatePresignedUrls(payload: PresignedUrlPayload[]): Observable<{[key:string]: PresignedUrlResponse}> {
    const url = 'Assets/GeneratePresignedUrls';

    return this.apiGeneralService.sendRequest({
      method: RequestType.post,
      url,
      data: payload
    });
  }
}
