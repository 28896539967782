import {FormControl, ValidationErrors} from '@angular/forms';

const macroNames = [
  'IP',
  'OS_VERSION',
  'IDFA',
  'IDFV',
  'GAID',
  'CAMPAIGN_ID',
  'CAMPAIGN_NAME',
  'CREATIVE_ID',
  'CREATIVE_NAME',
  'SOURCE_APP_ID',
  'SOURCE_APP_NAME',
  'AD_FORMAT',
  'TARGET_APP_ID',
  'MORE_GAMES_PLACEMENT',
  'MORE_GAMES_PANEL',
  'REGION',
  'DEVICE_MODEL',
  'OS_TYPE'
];

export function trackingLinkValidator(control: FormControl<string>): ValidationErrors | null {
  if (!control.value?.trim()) {
    return null;
  }

  if(!startsWithHttps(control)) {
    return {trackingLinkHttpsError: true};
  }
  if (!isFormatValid(control)) {
    return {trackingLinkFormatError: true};
  }
  return macrosValidator(control);
}

function startsWithHttps(control: FormControl<string>): boolean {
  return control.value.trim().startsWith('https://');
}

function isFormatValid(control: FormControl<string>): boolean {
  const urlWithMacrosRegex = /^https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#={}]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//={}]*)$/;
  return urlWithMacrosRegex.test(control.value);
}

function macrosValidator(control: FormControl<string>): ValidationErrors | null {
  const macros = getMacros(control);
  const invalidMacros: string[] = getInvalidMacros(macros);

  if (invalidMacros.length > 0) {
    return {trackingLinkIncorrectMacros: true, invalidMacros: invalidMacros.join(', ')};
  }
  return null;
}

export function getMacros(control: FormControl<string>): string[] {
  const regex = /{([^}]*)}/g;
  const matches: IterableIterator<RegExpMatchArray> = control.value.matchAll(regex);
  const macros = [];
  for (const match of matches) {
    const placeholder = match[1];
    macros.push(placeholder);
  }
  return macros;
}

function getInvalidMacros(macros: string[]): string[] {
  return macros.filter(macro => !macroNames.includes(macro));
}

