import {Injectable} from '@angular/core';
import {ApiMoreGamesCsvService} from './api-more-games-csv.service';
import {IMoreGamesCSV} from '../interface/IMoreGamesCSV';
import {MoreGamesService} from './more-games.service';
import {ApiErrorService} from '../../shared/service/api/api-error.service';
import {firstValueFrom} from 'rxjs';

/**
 * Service for handling More Games CSV operations, such as uploading and validating CSV files.
 */
@Injectable({
  providedIn: 'root'
})
export class MoreGamesCsvService {

  /**
   * Constructor for MoreGamesCsvService.
   * @param apiCsvUploadService Instance of ApiMoreGamesCsvService for making API calls related to More Games CSV.
   * @param moreGamesService Instance of MoreGamesService for managing More Games data.
   * @param apiErrorService Instance of ApiErrorService for handling API error responses.
   */
  constructor(private apiCsvUploadService: ApiMoreGamesCsvService, private moreGamesService: MoreGamesService,
              private apiErrorService: ApiErrorService) {
  }

  /**
   * Uploads More Games CSV data.
   * @param rows Array of MoreGamesCSV representing the data to be uploaded.
   * @returns Promise<void> - Promise resolving when the upload is successful.
   * @throws Error - Throws an error if the upload fails, with additional error handling.
   */
  public async uploadCsv(rows: IMoreGamesCSV[]): Promise<void> {
    try {
      await firstValueFrom(this.apiCsvUploadService.uploadCsv(rows));
      this.moreGamesService.moreGamesChanged.next();
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'moreGamesImport.moreGamesImportCreationErrorMessage');
      throw error;
    }
  }

  /**
   * Validates a More Games CSV file.
   * @param file File representing the More Games CSV file to be validated.
   * @returns Promise<IMoreGamesCSV[]> - Promise resolving to the validated More Games CSV data.
   * @throws Error - Throws an error if the validation fails, with additional error handling.
   */
  public async validateCsv(file: File): Promise<IMoreGamesCSV[]> {
    try {
      return await firstValueFrom(this.apiCsvUploadService.validateCsv(file));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'import.fileUploadFailed');
      throw error;
    }
  }

  public async moreGamesDeleteCsv(file: File): Promise<void> {
    try {
      await firstValueFrom(this.apiCsvUploadService.moreGamesDeleteCsv(file));
    } catch (error) {
      this.apiErrorService.showApiErrorSnackbar(error, 'moreGames.moreGamesDeleteCsv.deleteError');
      throw error;
    }
  }
}
