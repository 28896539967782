import { Component, Inject, OnInit } from '@angular/core';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { DomSanitizer,SafeResourceUrl } from '@angular/platform-browser';

import { DialogRef } from 'src/app/shared/service/dialog/dialog/dialog-ref';
import { DIALOG_DATA } from 'src/app/shared/service/dialog/dialog/dialog-tokens';
import { IMoreGamesTemplate } from 'src/app/more-games/interface/IMoreGamesTemplate';

/**
 * Component responsible for displaying a preview of a template.
 * Displays the content of a template within an iframe for preview purposes.
 */
@Component({
  selector: 'app-template-preview',
  templateUrl: './template-preview.component.html',
  styleUrls: ['./template-preview.component.scss']
})
export class TemplatePreviewComponent implements OnInit {
  /** Font Awesome icon for closing the preview dialog. */
  public faXmark = faXmark;

  /** Safe URL for the template to be displayed in the iframe. */
  url: SafeResourceUrl;

  /** Flag indicating whether the iframe content is still loading. */
  public isLoading = true;

  /**
   * Constructor for TemplatePreviewComponent.
   * @param dialogRef Reference to the dialog containing the template preview.
   * @param data Data injected into the dialog, containing information about the template.
   * @param sanitizer Instance of DomSanitizer for sanitizing URLs and bypassing security checks.
   */
  constructor(
    public dialogRef: DialogRef, 
    @Inject(DIALOG_DATA) public data: IMoreGamesTemplate,
    public sanitizer: DomSanitizer,
  ) { }

  /** Initializes the component. */
  ngOnInit(): void {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  }

  /**
   * Callback function invoked when the iframe content has finished loading.
   * Sets the isLoading flag to false.
   */
  public onIFrameLoad(): void {
    this.isLoading = false;
  }

  /** Closes the template preview dialog. */
  public onClose(): void {
    this.dialogRef.close();
  }
}
