import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { RequestType } from 'src/app/shared/enum/RequestType';
import { ApiGeneralService } from '../../shared/service/api/api-general.service';
import { QueryService } from '../../shared/service/query.service';
import { IMoreGamesRaw } from '../interface/IMoreGamesRaw';
import { IMoreGamesDetails } from '../interface/IMoreGamesDetails';
import { ITemplate } from '../interface/IMoreGamesTemplate';

/**
 * Injectable service ApiMoreGamesService responsible for handling API requests related to More Games data.
 * It provides methods to interact with the backend API for operations such as fetching, creating, updating, and deleting More Games.
 */
@Injectable({
  providedIn: 'root'
})
export class ApiMoreGamesService {

  /**
   * Constructor for ApiMoreGamesService.
   * @param apiGeneralService Instance of the ApiGeneralService for sending API requests.
   * @param queryService Instance of the QueryService for generating query parameters.
   * @param httpClient Instance of the HttpClient for making HTTP requests.
   */
  constructor(
    private apiGeneralService: ApiGeneralService,
    private queryService: QueryService,
    private httpClient: HttpClient
  ) { }

  /**
   * Retrieves paginated and filtered More Games data from the backend.
   * @param query The query parameters for pagination and filtering.
   * @returns An Observable emitting the paginated and filtered More Games data upon a successful response.
   */
  public getMoreGamesPaginated(query: any): Observable<any> {
    const params = this.queryService.getHttpParams(query);
    const url = `MoreGames/PaginatedAndFiltered`;

    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
      params
    });
  }

  /**
   * Removes a More Games entry from the backend.
   * @param id The ID of the More Games entry to be removed.
   * @returns An Observable emitting a string indicating the success of the operation upon a successful response.
   */
  public removeMoreGames(id: string): Observable<string> {
    const url = `MoreGames/${id}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.delete,
      url,
    });
  }

  /**
   * Creates a new More Games entry on the backend.
   * @param moreGames The More Games data to be created.
   * @returns An Observable emitting void upon a successful creation.
   */
  public createMoreGames(moreGames: IMoreGamesRaw): Observable<void> {
    const url = 'MoreGames';
    return this.apiGeneralService.sendRequest({
      method: RequestType.post,
      url,
      data: moreGames
    });
  }

  /**
   * Updates an existing More Games entry on the backend.
   * @param moreGames The More Games data to be updated.
   * @returns An Observable emitting void upon a successful update.
   */
  public updateMoreGames(moreGames: IMoreGamesRaw): Observable<void> {
    const url = 'MoreGames';
    return this.apiGeneralService.sendRequest({
      method: RequestType.put,
      url,
      data: moreGames
    });
  }

  /**
   * Retrieves More Games entries associated with a specific product ID.
   * @param productId The ID of the product.
   * @returns An Observable emitting an array of More Games entries upon a successful response.
   */
  public getMoreGamesByProductId(productId: string): Observable<any[]> {
    const url = `MoreGames/GetByProduct/${productId}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }

  /**
   * Retrieves More Games entries associated with a specific platform ID.
   * @param platformId The ID of the platform.
   * @returns An Observable emitting an array of More Games entries upon a successful response.
   */
  public getMoreGamesByPlatformId(platformId: string): Observable<any[]> {
    const url = `MoreGames/GetByPlatform/${platformId}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }

  /**
   * Retrieves More Games entries associated with a specific internal ID.
   * @param id The internal ID of the More Games entry.
   * @returns An Observable emitting More Games data upon a successful response.
   */
  public getMoreGames(id: string): Observable<IMoreGamesDetails> {
    const url = `MoreGames/GetByInternalId/${id}`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }

  /**
   * Retrieves autocomplete suggestions for More Games names.
   * @returns An Observable emitting an array of string containing autocomplete suggestions for More Games names.
   */
  public getAutocomplete(): Observable<string[]> {
    const url = 'MoreGames/Names';
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }

  /**
   * Initiates the export of more games data to a CSV file by making a request to the server.
   * @returns An observable that emits the CSV data as a string upon a successful response.
   */
  public exportCsv(): Observable<string> {
    const url = this.apiGeneralService.getUrl('MoreGames/ExportCsv');
    return this.httpClient.get(url, { responseType: 'text' })
  }

  /**
   * Retrieves all available templates for More Games.
   * Fetches template data from the backend API to provide predefined structures for creating More Games entries.
   * @returns An Observable emitting an array of template objects upon a successful response.
   */
  public getAllTemplates(): Observable<ITemplate[]> {
    const url = 'MoreGames/Templates';
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }

  /**
   * Initiates regeneration of all More Games entries in the backend.
   * @returns An Observable emitting void upon successful regeneration.
   */
  public regenerateAllMoreGames(): Observable<void> {
    const url = 'MoreGames/Regenerate';
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }

  /**
   * Initiates regeneration of a specific More Games entry identified by ID in the backend.
   * @param id The ID of the More Games entry to regenerate.
   * @returns An Observable emitting void upon successful regeneration.
   */
  public regenerateMoreGame(id: string): Observable<void> {
    const url = `MoreGames/${id}/Regenerate`;
    return this.apiGeneralService.sendRequest({
      method: RequestType.get,
      url,
    });
  }
}
