<ng-container *ngIf="isDialog">
  <app-base-expanded-dialog [showConfirmPopup]="true" [backAction]="backAction" [closeDialogObservable]="closeDialogSubject.asObservable()">
    <ng-container *ngTemplateOutlet="creativeForm"></ng-container>
  </app-base-expanded-dialog>
</ng-container>

<ng-container *ngIf="!isDialog">
  <ng-container *ngTemplateOutlet="creativeForm"></ng-container>
</ng-container>


<ng-template #creativeForm>
  <hr *ngIf="!isDialog" class="nav-separator">
  <div class="form-page">

    <div [ngClass]="isDialog ? 'no-padding-top' : ''" class="add-form-header">
      <h1 *ngIf="!isEditMode">{{'creative.creativeForm.addCreativeHeader' | translate}}</h1>
      <h1 *ngIf="isEditMode">{{'creative.creativeForm.editCreativeHeader' | translate}}</h1>
      <p *ngIf="!isEditMode">{{'creative.creativeForm.addCreativeInfo' | translate}}</p>
      <p *ngIf="isEditMode">{{'creative.creativeForm.editCreativeInfo' | translate}}</p>
    </div>
    <app-spinner *ngIf="isFormLoading"></app-spinner>
    <div [ngClass]="{'form-shadow-bottom--hidden': !hasScroll}" class="creative-form form-shadow-bottom" #formScrollable>
      <form *ngIf="initialized" [formGroup]="formGroup">
        <div class="form-controls-group">
          <div class="form-controls-row">
            <div class="form-control">
              <div class="label">{{'adUnit.adUnit' | translate}} <span class="color-error">*</span></div>
              <app-single-select-input [items]="adUnitOptions"
                                       [label]="'creative.creativeForm.adUnitPlaceholder' | translate"
                                       formControlName="adUnit"></app-single-select-input>
              <span *ngIf="hasRequiredError(formGroup.get('adUnit'))" class="error-message">
            {{'form.fieldRequired' | translate}}
          </span>
            </div>
            <div *ngIf="!formGroup.controls.isSplitScreen.value" class="form-control">
              <div class="label">{{'creative.creativeForm.productName' | translate}} <span class="color-error">*</span>
              </div>
              <app-select-product-control [placeholder]="'creative.creativeForm.productNamePlaceholder' | translate"
                                          [isMultiSelect]="false"
                                          formControlName="product"></app-select-product-control>
              <span *ngIf="hasRequiredError(formGroup.get('product'))" class="error-message">
            {{'form.fieldRequired' | translate}}
          </span>
            </div>
            <div class="form-control">
              <div class="label">{{'creative.creativeForm.creativeName' | translate}} <span class="color-error">*</span>
              </div>
              <input name="creativeName" autocomplete="off" formControlName="name" type="text"
                     [placeholder]="'creative.creativeForm.creativeName' | translate">
              <span *ngIf="hasRequiredError(formGroup.get('name'))" class="error-message">
            {{'form.fieldRequired' | translate}}
          </span>
              <span *ngIf="hasError(formGroup.get('name'), 'creativeNameExists')"
                    class="error-message">{{'creative.creativeForm.creativeNameUniqMsg' | translate}}
        </span>
            </div>
          </div>
          <div class="form-controls-row mat-caption">
            <p><span class="color-error">*</span>{{'form.fieldRequiredDescription' | translate}}</p>
          </div>
          <div class="form-controls-row" *ngIf="isRewardedOrInterstitial()">
            <div class="align-center-gap-5 mat-caption">
              <app-toggle-slider formControlName="isStatic">
              </app-toggle-slider>
              <span>{{'creative.creativeForm.isStatic' | translate}}</span>
            </div>
            <div *ngIf="isStatic()" class="align-center-gap-5 mat-caption">
              <app-toggle-slider formControlName="useStaticForDefaultHtml">
              </app-toggle-slider>
              <span>{{'creative.creativeForm.useDefaultHtml' | translate}}</span>
            </div>

            <div class="align-center-gap-5 mat-caption">
              <app-toggle-slider formControlName="isSplitScreen">
              </app-toggle-slider>
              <span>{{'creative.creativeForm.isSplitScreen' | translate}}</span>
            </div>
            <ng-container *ngIf="!!formGroup.controls.isSplitScreen.value">
<!--              [split_screen_landscape_disable] update after landscape support will we added on backend-->
<!--              <div class="align-center-gap-5 mat-caption">-->
<!--                <app-toggle-slider formControlName="isSplitScreenPortrait">-->
<!--                </app-toggle-slider>-->
<!--                <span>{{'creative.creativeForm.splitScreenPortrait' | translate}}</span>-->
<!--              </div>-->
<!--              <div class="align-center-gap-5 mat-caption">-->
<!--                <app-toggle-slider formControlName="isSplitScreenLandscape">-->
<!--                </app-toggle-slider>-->
<!--                <span>{{'creative.creativeForm.splitScreenLandscape' | translate}}</span>-->
<!--              </div>-->
              <div class="align-center-gap-5 mat-caption">
                <app-toggle-slider formControlName="isSplitScreenWith4Elements">
                </app-toggle-slider>
                <span>{{'creative.creativeForm.isSplitScreenWith4Elements' | translate}}</span>
              </div>
              <div class="align-center-gap-5 mat-caption">
                <app-toggle-slider formControlName="isSplitScreenWith2Elements">
                </app-toggle-slider>
                <span>{{'creative.creativeForm.isSplitScreenWith2Elements' | translate}}</span>
              </div>
            </ng-container>
          </div>
            <div *ngIf="formGroup.controls.isSplitScreen.value && !formGroup.controls.isSplitScreenPortrait.value && !formGroup.controls.isSplitScreenLandscape.value"
                 class="color-error mat-caption form-controls-row">{{'creative.creativeForm.selectPortraitOrLandscape' | translate}}</div>
          <!--              [split_screen_landscape_disable] update after landscape support will we added on backend-->
          <div *ngIf="formGroup.controls.isSplitScreen.value"
               class="color-warning-secondary mat-caption form-controls-row">
            <div>
              <fa-icon class="color-warning-secondary" [icon]="faCircleInfo">
              </fa-icon>
              Split screen functionality currently supports portrait mode only. Landscape support will be added soon.
            </div>
          </div>
        </div>
        <div *ngIf="isMoreGamesSelected()" class="form-controls-group">
          <div class="form-controls-row">
            <div class="form-control">
              <div class="label">
                {{'creative.creativeForm.description' | translate}}
                <fa-icon class="color-primary" [icon]="faCircleInfo"
                         [appTooltip]="'creative.creativeForm.descriptionTooltip' | translate">
                </fa-icon>
              </div>
              <input name="description" autocomplete="off" formControlName="description" type="text"
                     [placeholder]="'creative.creativeForm.description' | translate">
            </div>
            <div class="form-control">
              <div class="label">
                {{'creative.creativeForm.incentiveText' | translate}}
                <fa-icon class="color-primary" [icon]="faCircleInfo"
                         [appTooltip]="'creative.creativeForm.incentiveTextTooltip' | translate">
                </fa-icon>
              </div>
              <input name="incentiveText" autocomplete="off" formControlName="incentiveText" type="text"
                     [placeholder]="'creative.creativeForm.incentiveText' | translate">
            </div>
          </div>
        </div>
      </form>
      <div class="form-controls-group">
        <div class="form-controls-row align-center">
          <h2>{{'creative.creativeForm.uploadAssetHeader' | translate}}</h2>
          <a href="https://core.boombit.com/docs/_cross_promotion_baner.html" target="_blank">
            <button class="button-narrow" mat-stroked-button color="primary">
              <fa-icon [icon]="faArrowUpRightFromSquare"></fa-icon>
              {{'creative.creativeForm.assetsDocsButton' | translate}}
            </button>
          </a>
        </div>
        <ng-container *ngIf="initialized">
          <ng-container *ngIf="!formGroup.controls.isSplitScreen.value" [ngTemplateOutlet]="standardAssetUpload">
          </ng-container>
          <ng-container *ngIf="!!formGroup.controls.isSplitScreen.value" [ngTemplateOutlet]="splitScreenAssetUpload">
          </ng-container>
        </ng-container>
      </div>
    </div>
    <hr>
    <div class="add-form-action-buttons">
      <button (click)="onCancelClicked()" mat-stroked-button color="primary">
        {{'button.cancel' | translate}}
      </button>
      <button [disabled]="isSubmitDisabled()" (click)="onSubmitClicked()" mat-flat-button color="primary">
        <fa-icon *ngIf="!isPendingButonVisible()" [icon]="faFloppyDiskCircleArrowRight"></fa-icon>
        <ng-container *ngIf="!isPendingButonVisible()">{{'creative.creativeForm.saveCreativeButton' | translate}}</ng-container>
        <mat-spinner *ngIf="isPendingButonVisible()" [diameter]="20" color="accent"></mat-spinner>
        <span *ngIf="isPendingButonVisible()">{{'creative.creativeForm.pendingButton' | translate}}</span>
      </button>
      <mat-spinner *ngIf="isSubmitLoading" [diameter]="30"></mat-spinner>
    </div>
  </div>
</ng-template>


<ng-template #standardAssetUpload>
  <div [ngStyle]="{'grid-template-columns': 'repeat(' + columnsCount + ', auto)'}" class="asset-inputs-row">
    <div *ngFor="let assetControl of formGroup.controls.regularAssets.controls">
      <ng-template [ngTemplateOutlet]="assetInput" [ngTemplateOutletContext]="{assetGroup: assetControl}"></ng-template>
    </div>
  </div>
</ng-template>


<ng-template #splitScreenAssetUpload>
  <ng-container *ngFor="let section of formGroup.controls.splitScreenSections.controls; let i = index">
    <div style="align-items: flex-end" class="form-controls-row">
    <div class="form-control">
      <div class="label">
        <ng-container *ngIf="formGroup.controls.isSplitScreenWith4Elements.value">
          <fa-icon *ngIf="i === 0" flip="horizontal" [icon]="faCorner"></fa-icon>
          <fa-icon *ngIf="i === 1" [icon]="faCorner"></fa-icon>
          <fa-icon *ngIf="i === 2" flip="both" [icon]="faCorner"></fa-icon>
          <fa-icon *ngIf="i === 3" flip="vertical" [icon]="faCorner"></fa-icon>
        </ng-container>
        <ng-container *ngIf="!formGroup.controls.isSplitScreenWith4Elements.value">
          <span>
          <fa-icon class="txt-3-regular" *ngIf="i === 0" flip="horizontal" [icon]="faCorner"></fa-icon>
          <fa-icon class="txt-3-regular" *ngIf="i === 0" [icon]="faCorner"></fa-icon>
          </span>
          <span>
          <fa-icon class="txt-3-regular" *ngIf="i === 1" flip="both" [icon]="faCorner"></fa-icon>
          <fa-icon class="txt-3-regular" *ngIf="i === 1" flip="vertical" [icon]="faCorner"></fa-icon>
          </span>
        </ng-container>

        {{'creative.creativeForm.productSplitName' | translate}} <span class="color-error">*</span>
      </div>
      <app-select-product-control [placeholder]="'creative.creativeForm.productNamePlaceholder' | translate"
                                  [isMultiSelect]="false"
                                  [formControl]="section.controls.product"></app-select-product-control>
      <span *ngIf="hasRequiredError(section.controls.product)" class="error-message">
            {{'form.fieldRequired' | translate}}
          </span>
      <span *ngIf="section.controls.product.hasError('productDuplicationError')" class="error-message">
            {{'form.productDuplicatedError' | translate}}
          </span>
    </div>
    <div class="align-center-gap-5 mat-caption">
      <span>{{'creative.creativeForm.withSound' | translate}}</span>
      <app-toggle-slider [formControl]="section.controls.withSound">
      </app-toggle-slider>
      <fa-icon class="color-primary" [icon]="faCircleInfo"
               [appTooltip]="'creative.creativeForm.splitScreenAudioInfo' | translate">
      </fa-icon>
    </div>
    </div>
  <div [ngStyle]="{'grid-template-columns': 'repeat(' + 2 + ', auto)'}" class="asset-inputs-row">
      <div *ngFor="let assetGroup of section.controls.assetControls.controls">
        <ng-template [ngTemplateOutlet]="assetInputSplit" [ngTemplateOutletContext]="{assetGroup: assetGroup, idx: i}"></ng-template>
      </div>
  </div>
  </ng-container>
</ng-template>


<ng-template #assetInput let-assetGroup="assetGroup">
  <div class="d-inline-block">
    <p class="margin-0 color-primary ad-unit-format-label">
      {{assetGroupType(assetGroup).controls.adUnitFormat.value.userFriendlyName}}
      <span *ngIf="assetGroupType(assetGroup).controls.adUnitFormat.value.required" class="color-error">*</span>
    </p>
    <app-asset-file-input (fileRemoved)="onAssetDeleteForm(assetGroupType(assetGroup))"
                          (fileSelected)="onFileSelectedForm($event, assetGroupType(assetGroup))"
                          (addFromLibraryClicked)="onOpenAssetLibraryClickedForm(assetGroupType(assetGroup), false)"
                          [insideCreativeForm]="true" [status]="getStatus(assetGroup)"
                          [asset]="assetGroupType(assetGroup).controls.asset.value">
    </app-asset-file-input>
  </div>
  <div class="asset-requirements">
    <p *ngIf="assetGroupType(assetGroup).controls.asset.pending" class="mat-caption align-center-gap-5">
      <mat-spinner [diameter]="20" color="accent"></mat-spinner>
      {{'form.validating' | translate}}
    </p>
    <p *ngIf="checksumExists(assetGroupType(assetGroup).controls.asset.value)" class="mat-caption asset-requirement__long">
      <fa-icon class="color-warning-secondary x-mark-icon" [icon]="faTriangleExclamation"></fa-icon>
      {{'asset.assetRequirements.checksumUniqWarning' | translate}}
    </p>
    <p *ngIf="assetGroupType(assetGroup).controls.asset.hasError('adUnitFormatMatchError')" class="mat-caption">
      <fa-icon class="color-error" [icon]="faXMark"></fa-icon>
      {{'asset.assetRequirements.adUnitFormatMatch' | translate}} {{assetGroupType(assetGroup).controls.adUnitFormat.value.name}}
    </p>
    <p *ngIf="assetGroupType(assetGroup).controls.adUnitFormat.value.required" class="mat-caption">
      <fa-icon
        [ngClass]="!assetGroupType(assetGroup).controls.asset.hasError('required') ? 'color-success' : 'color-error'"
        [icon]="!assetGroupType(assetGroup).controls.asset.hasError('required') ? faCheck : faXMark"></fa-icon>
      {{'asset.assetRequirements.required' | translate}}
    </p>
    <p *ngIf="assetGroupType(assetGroup).controls.asset.hasError('nameExistsError')" class="mat-caption">
      <fa-icon class="color-error x-mark-icon" [icon]="faXMark"></fa-icon>
      {{'asset.assetRequirements.fileNameUniqError' | translate}}
    </p>
    <p *ngIf="assetGroupType(assetGroup).controls.asset.hasError('nameDuplicatedError')" class="mat-caption">
      <fa-icon class="color-error x-mark-icon" [icon]="faXMark"></fa-icon>
      {{'asset.assetRequirements.fileNameDuplicatedError' | translate}}
    </p>
    <p class="mat-caption">
      <fa-icon [ngClass]="!assetGroupType(assetGroup).controls.asset.hasError('fileFormatError') ? 'color-success' : 'color-error'"
               [icon]="!assetGroupType(assetGroup).controls.asset.hasError('fileFormatError') ? faCheck : faXMark"></fa-icon>
      {{'asset.assetRequirements.format' | translate}}: {{assetGroupType(assetGroup).controls.adUnitFormat.value.formats.join(', ')}}
    </p>
    <p *ngIf="!!assetGroupType(assetGroup).controls.adUnitFormat.value.ratio" class="mat-caption">
      <fa-icon [ngClass]="!assetGroupType(assetGroup).controls.asset.hasError('ratioError') ? 'color-success' : 'color-error'"
               [icon]="!assetGroupType(assetGroup).controls.asset.hasError('ratioError') ? faCheck : faXMark"></fa-icon>
      {{'asset.assetRequirements.ratio' | translate}}: {{assetGroupType(assetGroup).controls.adUnitFormat.value.ratio}}
    </p>
    <p class="mat-caption">
      <fa-icon [ngClass]="!assetGroupType(assetGroup).controls.asset.hasError('resolutionError') ? 'color-success' : 'color-error'"
               [icon]="!assetGroupType(assetGroup).controls.asset.hasError('resolutionError') ? faCheck : faXMark"></fa-icon>
      {{'asset.assetRequirements.resolution' | translate}}
      : {{getResolutionLabel(assetGroupType(assetGroup).controls.adUnitFormat.value.resolution,
      assetGroupType(assetGroup).controls.adUnitFormat.value.ratio)}}
    </p>
    <p class="mat-caption">
      <fa-icon [ngClass]="!assetGroupType(assetGroup).controls.asset.hasError('maxSizeError') ? 'color-success' : 'color-error'"
               [icon]="!assetGroupType(assetGroup).controls.asset.hasError('maxSizeError') ? faCheck : faXMark"></fa-icon>
      {{'asset.assetRequirements.maxSize' | translate}}: {{assetGroupType(assetGroup).controls.adUnitFormat.value.maxSize | fileSize}}
    </p>
    <p class="mat-caption">
      <fa-icon [ngClass]="!assetGroupType(assetGroup).controls.asset.hasError('maxDurationError') ? 'color-success' : 'color-error'"
               [icon]="!assetGroupType(assetGroup).controls.asset.hasError('maxDurationError') ? faCheck : faXMark"></fa-icon>
      {{'asset.assetRequirements.maxDuration' | translate}}: {{assetGroupType(assetGroup).controls.adUnitFormat.value.maxDuration + 's'}}
    </p>
  </div>
</ng-template>


<ng-template #assetInputSplit let-assetGroup="assetGroup" let-idx="idx">
  <div class="d-inline-block">
    <p class="margin-0 color-primary ad-unit-format-label">
      <ng-container *ngIf="formGroup.controls.isSplitScreenWith4Elements.value">
        <fa-icon *ngIf="idx === 0" flip="horizontal" [icon]="faCorner"></fa-icon>
        <fa-icon *ngIf="idx === 1" [icon]="faCorner"></fa-icon>
        <fa-icon *ngIf="idx === 2" flip="both" [icon]="faCorner"></fa-icon>
        <fa-icon *ngIf="idx === 3" flip="vertical" [icon]="faCorner"></fa-icon>
      </ng-container>
      <ng-container *ngIf="!formGroup.controls.isSplitScreenWith4Elements.value">
        <span>
          <fa-icon class="txt-3-regular" *ngIf="idx === 0" flip="horizontal" [icon]="faCorner"></fa-icon>
          <fa-icon class="txt-3-regular" *ngIf="idx === 0" [icon]="faCorner"></fa-icon>
        </span>
        <span>
          <fa-icon class="txt-3-regular" *ngIf="idx === 1" flip="both" [icon]="faCorner"></fa-icon>
          <fa-icon class="txt-3-regular" *ngIf="idx === 1" flip="vertical" [icon]="faCorner"></fa-icon>
        </span>
      </ng-container>
      {{assetGroupType(assetGroup).controls.adUnitFormat.value.userFriendlyName}}
      <span *ngIf="assetGroupType(assetGroup).controls.asset.hasValidator(Validators.required)" class="color-error">*</span>
    </p>
    <app-asset-file-input (fileRemoved)="onAssetDeleteForm(assetGroupType(assetGroup))"
                          (fileSelected)="onFileSelectedForm($event, assetGroupType(assetGroup))"
                          (addFromLibraryClicked)="onOpenAssetLibraryClickedForm(assetGroupType(assetGroup), true)"
                          [insideCreativeForm]="true" [status]="getStatus(assetGroup)"
                          [asset]="assetGroupType(assetGroup).controls.asset.value">
    </app-asset-file-input>
  </div>
  <div class="asset-requirements">
    <p *ngIf="checksumExists(assetGroupType(assetGroup).controls.asset.value)" class="mat-caption asset-requirement__long">
      <fa-icon class="color-warning-secondary x-mark-icon" [icon]="faTriangleExclamation"></fa-icon>
      {{'asset.assetRequirements.checksumUniqWarning' | translate}}
    </p>
    <p *ngIf="assetGroupType(assetGroup).controls.asset.hasError('adUnitFormatMatchError')" class="mat-caption">
      <fa-icon class="color-error" [icon]="faXMark"></fa-icon>
      {{'asset.assetRequirements.adUnitFormatMatch' | translate}} {{assetGroupType(assetGroup).controls.adUnitFormat.value.name}}
    </p>
    <p *ngIf="assetGroupType(assetGroup).controls.asset.hasError('endcardSplitScreenError')" class="mat-caption">
      <fa-icon class="color-error" [icon]="faXMark"></fa-icon>
      <span [innerHTML]="'asset.assetRequirements.endcardSplitScreenError' | translate"></span>
    </p>
    <p *ngIf="assetGroupType(assetGroup).controls.asset.hasValidator(Validators.required)" class="mat-caption">
      <fa-icon
        [ngClass]="!assetGroupType(assetGroup).controls.asset.hasError('required') ? 'color-success' : 'color-error'"
        [icon]="!assetGroupType(assetGroup).controls.asset.hasError('required') ? faCheck : faXMark"></fa-icon>
      {{'asset.assetRequirements.required' | translate}}
    </p>
    <p *ngIf="assetGroupType(assetGroup).controls.asset.hasError('nameExistsError')" class="mat-caption">
      <fa-icon class="color-error x-mark-icon" [icon]="faXMark"></fa-icon>
      {{'asset.assetRequirements.fileNameUniqError' | translate}}
    </p>
    <p *ngIf="assetGroupType(assetGroup).controls.asset.hasError('nameDuplicatedError')" class="mat-caption">
      <fa-icon class="color-error x-mark-icon" [icon]="faXMark"></fa-icon>
      {{'asset.assetRequirements.fileNameDuplicatedError' | translate}}
    </p>
    <p class="mat-caption">
      <fa-icon [ngClass]="!assetGroupType(assetGroup).controls.asset.hasError('fileFormatError') ? 'color-success' : 'color-error'"
               [icon]="!assetGroupType(assetGroup).controls.asset.hasError('fileFormatError') ? faCheck : faXMark"></fa-icon>
      {{'asset.assetRequirements.format' | translate}}: {{assetGroupType(assetGroup).controls.adUnitFormat.value.formats.join(', ')}}
    </p>
    <p *ngIf="!!assetGroupType(assetGroup).controls.adUnitFormat.value.ratio" class="mat-caption">
      <fa-icon [ngClass]="!assetGroupType(assetGroup).controls.asset.hasError('ratioError') ? 'color-success' : 'color-error'"
               [icon]="!assetGroupType(assetGroup).controls.asset.hasError('ratioError') ? faCheck : faXMark"></fa-icon>
      {{'asset.assetRequirements.ratio' | translate}}: {{assetGroupType(assetGroup).controls.adUnitFormat.value.ratio}}
    </p>
    <p class="mat-caption">
      <fa-icon [ngClass]="!assetGroupType(assetGroup).controls.asset.hasError('resolutionError') ? 'color-success' : 'color-error'"
               [icon]="!assetGroupType(assetGroup).controls.asset.hasError('resolutionError') ? faCheck : faXMark"></fa-icon>
      {{'asset.assetRequirements.resolution' | translate}}
      : {{getResolutionLabel(assetGroupType(assetGroup).controls.adUnitFormat.value.resolution,
      assetGroupType(assetGroup).controls.adUnitFormat.value.ratio)}}
    </p>
    <p class="mat-caption">
      <fa-icon [ngClass]="!assetGroupType(assetGroup).controls.asset.hasError('maxSizeError') ? 'color-success' : 'color-error'"
               [icon]="!assetGroupType(assetGroup).controls.asset.hasError('maxSizeError') ? faCheck : faXMark"></fa-icon>
      {{'asset.assetRequirements.maxSize' | translate}}: {{assetGroupType(assetGroup).controls.adUnitFormat.value.maxSize | fileSize}}
    </p>
    <p class="mat-caption">
      <fa-icon [ngClass]="!assetGroupType(assetGroup).controls.asset.hasError('maxDurationError') ? 'color-success' : 'color-error'"
               [icon]="!assetGroupType(assetGroup).controls.asset.hasError('maxDurationError') ? faCheck : faXMark"></fa-icon>
      {{'asset.assetRequirements.maxDuration' | translate}}: {{assetGroupType(assetGroup).controls.adUnitFormat.value.maxDuration + 's'}}
    </p>
  </div>
</ng-template>
