import {FormControl, FormGroup, ValidationErrors} from '@angular/forms';

import {IBasicSelectOption} from 'src/app/shared/interface/ui/form/IBasicSelectOption';
import {IMoreGamesForm} from '../interface/IMoreGamesForm';
import {PlatformType} from 'src/app/product/enum/PlatformType';
import {IProduct} from 'src/app/product/interface/IProduct';
import {IPlatformSmallInfo} from 'src/app/product/interface/platform/IPlatformSmallInfo';
import {IPromotedProductCampaignMG} from '../interface/IPromotedProductCampaignMG';

/**
 * Validation function that checks if the selected platform is defined for the chosen product.
 * If the platform is not defined, the function returns a "platformNotDefined" error.
 *
 * @param formControl Form control representing the selected platform.
 * @returns Validation errors object or null if no errors.
 */

export function platformDefined(formControl: FormControl<IBasicSelectOption>): ValidationErrors | null {
  const parentForm = formControl.parent as FormGroup<IMoreGamesForm>;
  if (preventPlatformValidation(formControl)) {
    return null;
  }
  const productControl = parentForm?.controls?.sourceProduct?.value;
  const productPlatformTypes = productControl?.platforms?.map(platform => platform.type);
  return productPlatformTypes.includes(formControl.value?.name as PlatformType) ? null : {platformNotDefined: true};
}

/**
 * Validation function that checks if the selected platform is enabled for the chosen product.
 * If the platform is not enabled, the function returns a "platformDisabled" error.
 *
 * @param formControl Form control representing the selected platform.
 * @returns Validation errors object or null if no errors.
 */
export function platformEnabled(formControl: FormControl<IBasicSelectOption>): ValidationErrors | null {
  const hasPlatformDefinedError = platformDefined(formControl);
  if (hasPlatformDefinedError || preventPlatformValidation(formControl)) {
    return null;
  }

  const parentForm = formControl.parent as FormGroup<IMoreGamesForm>;
  const productControl = parentForm?.controls?.sourceProduct?.value;
  const platform = productControl?.platforms?.find(pl => pl.type === formControl.value?.name);
  return platform?.disabled === true ? {platformDisabled: true} : null;
}

/**
 * Helper function that checks if platform validation should be prevented.
 *
 * @param platformControl Form control representing the selected platform.
 * @returns True if platform validation should be prevented, otherwise false.
 */
function preventPlatformValidation(platformControl: FormControl<IBasicSelectOption>): boolean {
  const parentForm = platformControl.parent as FormGroup<IMoreGamesForm>;
  return !parentForm || !parentForm?.controls?.sourceProduct?.value || !platformControl.value;
}

export function campaignsRequired(formArray: FormControl<IPromotedProductCampaignMG[]>): null | ValidationErrors {
  return hasCampaigns(formArray.value) ? null : {campaignsRequired: true};
}

export function hasCampaigns(productsCampaigns: IPromotedProductCampaignMG[]): boolean {
  return productsCampaigns.every(productCampaign => !!productCampaign.campaign?.id);
}

export function creativesRequired(formArray: FormControl<IPromotedProductCampaignMG[]>): null | ValidationErrors {
  return hasCreatives(formArray.value) ? null : {creativesRequired: true};
}

export function hasCreatives(productsCampaigns: IPromotedProductCampaignMG[]): boolean {
  return hasCampaigns(productsCampaigns) && productsCampaigns.every(productCampaign => productCampaign.campaign?.creatives?.length > 0);
}

export function platformForDestinationRequired(formControl: FormControl<IPromotedProductCampaignMG[]>): null | ValidationErrors {
  const parentForm = formControl?.parent as FormGroup<IMoreGamesForm>;
  const platformType: PlatformType = parentForm?.controls?.platform?.value?.name as PlatformType;
  if (!platformType) {
    return null;
  }

  for (const productCampaign of formControl.value) {
    if (!isDestinationPlatformValid(productCampaign?.product, platformType)) {
      return {platformForDestinationRequired: true};
    }
  }

  return null;
}

export function isDestinationPlatformValid(product: IProduct, platformType: PlatformType): boolean {
  return !hasDestinationPlatformMissing(product, platformType) || isSamsungAndroidCombination(product, platformType);
}

export function isSamsungAndroidCombination(product: IProduct, platformType: PlatformType): boolean {
  return platformType === PlatformType.SAMSUNG && !!product?.platforms?.some(platform => platform.type === PlatformType.ANDROID);
}

export function hasDestinationPlatformMissing(destination: IProduct, platformType: PlatformType): boolean {
  return !(platformType && hasPlatformOrWeb(destination, platformType));
}

function hasPlatformOrWeb(destination: IProduct, platformType: PlatformType): boolean {
  return destination?.platforms?.some(platform => platform.type === platformType || platform.type === PlatformType.WEB);
}

export function platformForDestinationEnabled(formControl: FormControl<IPromotedProductCampaignMG[]>): null | ValidationErrors {
  const parentForm = formControl?.parent as FormGroup<IMoreGamesForm>;
  const platformType: PlatformType = parentForm?.controls?.platform?.value?.name as PlatformType;
  if (!platformType) {
    return null;
  }

  for (const productCampaign of formControl.value) {
    if (hasDestinationPlatformDisabled(productCampaign?.product, platformType)) {
      return {platformForDestinationDisabled: true}
    }
  }

  return null;
}

export function hasDestinationPlatformDisabled(product: IProduct, platformType: PlatformType): boolean {
  if (!isDestinationPlatformValid(product, platformType)) {
    return false;
  }

  const destinationPlatform = getDestinationPlatform(product, platformType);
  return destinationPlatform.disabled === true;
}

function getDestinationPlatform(product: IProduct, platformType: PlatformType): IPlatformSmallInfo {
  const platformMatch = product?.platforms?.find((platform) => platform?.type === platformType);
  const isSamsungWithAndroidMatch = isSamsungAndroidCombination(product, platformType);
  const webPlatform = product?.platforms?.find((platform) => platform?.type === PlatformType.WEB);
  if (platformMatch) {
    return platformMatch;
  } else if (isSamsungWithAndroidMatch) {
    return product?.platforms?.find((platform) => platform?.type === PlatformType.ANDROID);
  } else if (webPlatform) {
    return webPlatform;
  } else {
    return null;
  }
}
