import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ChecksumGeneratorService {

  constructor() { }

  /**
   * Generate an SHA-256 checksum for the given blob using the Web Crypto API (built into the browser)
   */
  public async getChecksumSha256(blob: Blob): Promise<string> {
    const uint8Array = new Uint8Array(await blob.arrayBuffer());
    const hashBuffer = await crypto.subtle.digest('SHA-256', uint8Array);
    const hashArray = Array.from(new Uint8Array(hashBuffer));

    return hashArray.map((h) => h.toString(16).padStart(2, '0')).join('');
  }
}
