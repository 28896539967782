import {ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, Optional} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup} from '@angular/forms';
import {firstValueFrom, Observable, Subject, Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {faArrowLeft, faFloppyDiskCircleArrowRight} from '@fortawesome/pro-light-svg-icons';

import {IFormStepsComponent} from '../../../shared/interface/form-tabs/IFormStepsComponent';
import {ICanDeactivateFormCmp} from '../../../shared/interface/ui/form/ICanDeactivateFormCmp';
import {Tab} from '../../../shared/interface/ui/tabs/Tab';
import {EnumTranslateService} from '../../../shared/service/translate/enum-translate.service';
import {BACK_ACTION} from '../../../shared/service/dialog/dialog/dialog-tokens';
import {IBackAction} from '../../../shared/interface/ui/dialog/IBackAction';
import {LoadingService} from '../../../shared/service/loading/loading.service';
import {SnackbarService} from '../../../shared/service/snackbar/snackbar.service';
import {FormStepsHandler} from '../../../shared/class/form-steps-handler';
import {MoreGamesEndpoints} from '../../../shared/enum/Endpoints';
import {IBasicSelectOption} from '../../../shared/interface/ui/form/IBasicSelectOption';
import {PlatformType} from '../../../product/enum/PlatformType';
import {IProduct} from '../../../product/interface/IProduct';
import {MoreGamesService} from '../../service/more-games.service';
import {IMoreGamesDetails} from '../../interface/IMoreGamesDetails';
import {IMoreGamesForm} from '../../interface/IMoreGamesForm';
import {MoreGamesFormSteps} from '../../enum/IMoreGamesFormSteps';
import {MoreGamesFormService} from '../../service/more-games-form.service';
import {Locations} from '../../enum/Locations';
import {IPromotedProductCampaignMG} from '../../interface/IPromotedProductCampaignMG';
import {getControlsForLocation, getProductsCampaignsControl} from '../../util/more-games.util';

@Component({
  selector: 'app-more-games-form',
  templateUrl: './more-games-form.component.html',
  styleUrl: './more-games-form.component.scss'
})
export class MoreGamesFormComponent implements OnInit, IFormStepsComponent, ICanDeactivateFormCmp, OnDestroy {
  @Input() isDialog = false;
  @Input() isEditMode = false;
  @Input() initialValue: IMoreGamesDetails;
  @Input() public initialMoreGamesId: string;
  @Input() afterCloseRouterLink: string;

  public canDeactivateForm = false;
  public initialStep: string;
  public faArrowLeft = faArrowLeft;
  public faFloppyDiskCircleArrowRight = faFloppyDiskCircleArrowRight;
  public formGroup: FormGroup<IMoreGamesForm>;
  public showFullForm = true;
  public steps: Tab[] = [];
  public activeStep: Tab;
  public MoreGamesFormSteps = MoreGamesFormSteps;
  public closeDialogSubject = new Subject<boolean>();
  public formStepsHandler: FormStepsHandler;
  public isLoading = false;
  public isFormLoading = false;
  public subscriptions: Subscription[] = [];
  private _productsCampaignsRefresh: Subject<void> = new Subject<void>();
  public productsCampaignsRefresh: Observable<void> = this._productsCampaignsRefresh.asObservable();
  public currentLocation: Locations = Locations.TOP;

  constructor(
    private enumTranslateService: EnumTranslateService,
    private router: Router,
    private moreGamesService: MoreGamesService,
    @Optional() @Inject(BACK_ACTION) public backAction: IBackAction,
    public changeDetectorRef: ChangeDetectorRef,
    private loadingService: LoadingService,
    private route: ActivatedRoute,
    private location: Location,
    private translateService: TranslateService,
    private snackbarService: SnackbarService,
    private moreGamesFormService: MoreGamesFormService,
  ) {
    this.initialMoreGamesId = this.route.snapshot.paramMap.get('id');
    this.isEditMode = this.isDialog ? this.isEditMode : this.route.snapshot.data.isEditMode;
    // @ts-ignore
    this.initialStep = this.location.getState()?.stepName;
    // @ts-ignore
    this.afterCloseRouterLink = this.location.getState()?.afterCloseLink;
    this.formStepsHandler = new FormStepsHandler(this);
  }

  private _loadingEndpointNames(): string[] {
    return [MoreGamesEndpoints.getMoreGamesByInternalId(this.initialMoreGamesId)];
  }

  ngOnInit(): void {
    this.observeLoading();
    this.showFullForm = !(this.isEditMode && this.isDialog);
    this.initData();
  }

  private observeLoading(): void {
    const subscription = this.loadingService.loadingEndpointsObs()
      .subscribe((loadingState) => {
        this.isFormLoading = this.loadingService.areEndpointsLoading(this._loadingEndpointNames(), loadingState);
      });
    this.subscriptions.push(subscription);
  }

  private async initData(): Promise<void> {
    await this.initMoreGames();
    this.initForm().then(async () => {
      this.observeFormFields();
      this.initPlatforms();
      await this.initSteps();
      this.formStepsHandler.updateStepsAfterFormChanges();
    });
  }

  private async initMoreGames(): Promise<void> {
    this.initialValue = this.initialMoreGamesId ?
      await this.moreGamesService.getMoreGamesByInternalId(this.initialMoreGamesId) : this.initialValue;
  }

  private async initForm(): Promise<void> {
    this.formGroup = await this.moreGamesFormService.initForm(this.initialValue, this.formGroup) as FormGroup<IMoreGamesForm>;
    this.showErrorsInSummary();
  }

  private async initSteps(): Promise<void> {
    this.steps = await this.getInitialSteps();
    if (this.initialStep) {
      this.activeStep = this.steps.find(step => step.name === this.initialStep) || this.steps[0];
    } else {
      this.activeStep = this.steps[0];
    }
    this.steps.forEach(step => step.isDisabledFn = this.isStepDisabled.bind(this));
  }

  private async getInitialSteps(): Promise<Tab[]> {
    const steps = await this.enumTranslateService.getSelectOptionsFromEnum(MoreGamesFormSteps, 'moreGames.moreGamesForm.tabs.');
    return this.isEditMode ? steps.slice(1) : steps;
  }

  private async initPlatforms(): Promise<void> {
    const platformOptions: IBasicSelectOption[] = await this.enumTranslateService.getSelectOptionsFromEnum(PlatformType, 'platformType.');
    if (this.initialValue?.platform) {
      const selectedPlatform = platformOptions.find(el => el.name === this.initialValue.platform.type);
      this.formGroup.controls.platform.setValue(selectedPlatform);
    }
  }

  private showErrorsInSummary(): void {
    if (this.isEditMode) {
      this.formGroup.markAllAsTouched();
    }
  }

  private observeFormFields(): void {
    this.observeProduct();
    this.observePlatform();
    this.observeProductCampaigns();
    this.observeAdUnitFormats();
  }

  private observeAdUnitFormats(): void {
    const subTop = this.formGroup.controls.topPanelAdUnitFormatType.valueChanges.subscribe(() => {
      this.formGroup.controls.topPromotedProductsCampaigns.updateValueAndValidity();
    });
    const subMiddle = this.formGroup.controls.middlePanelAdUnitFormatType.valueChanges.subscribe(() => {
      this.formGroup.controls.middlePromotedProductsCampaigns.updateValueAndValidity();
    });
    const subBottom = this.formGroup.controls.bottomPanelAdUnitFormatType.valueChanges.subscribe(() => {
      this.formGroup.controls.bottomPromotedProductsCampaigns.updateValueAndValidity();
    });
    this.subscriptions.push(subTop, subMiddle, subBottom);
  }


  private observeProduct(): void {
    const sub = this.formGroup.controls.sourceProduct.valueChanges.subscribe(() => {
      this.formGroup.controls.platform.setValue(null);
      this.updateProductCampaignsValidity();
    });
    this.subscriptions.push(sub);
  }

  private observePlatform(): void {
    const sub = this.formGroup.controls.platform.valueChanges.subscribe(() => {
      this.updateProductCampaignsValidity();
    });
    this.subscriptions.push(sub);
  }

  private updateProductCampaignsValidity(): void {
    this.moreGamesFormService.locations.forEach(location => {
      getProductsCampaignsControl(this.formGroup, location.name).updateValueAndValidity({emitEvent: false});
    });
    this._productsCampaignsRefresh.next();
  }

  private observeProductCampaigns(): void {
    this.moreGamesFormService.locations.forEach(location => {
      const productCampaigns = getProductsCampaignsControl(this.formGroup, location.name);
      const sub = productCampaigns.valueChanges.subscribe(() => {
        productCampaigns.updateValueAndValidity({emitEvent: false});
        this._productsCampaignsRefresh.next();
      });
      this.subscriptions.push(sub);
    });
  }

  public onSourceProductSelected(product: IProduct): void {
    this.formGroup.controls.sourceProduct.setValue(product);
    this.onNextStepClicked();
  }

  public onSubmitClicked(): void {
    if (this.formGroup.valid) {
      this.isLoading = true;
      this.moreGamesService.createMoreGames(this.formGroup).then(async () => {
        const message = await firstValueFrom(this.translateService.get('moreGames.moreGamesCreatedMessage'));
        this.snackbarService.openSuccessSnackbar(message);
        this.canDeactivateForm = true;
        this.closeForm(true);
      }).finally(() => this.isLoading = false);
    }
  }

  public onSubmitEditClicked(): void {
    this.isLoading = true;
    this.moreGamesService.updateMoreGames(this.formGroup, this.initialValue.id).then(async () => {
      const message = await firstValueFrom(this.translateService.get('moreGames.moreGamesEditedMessage'));
      this.snackbarService.openSuccessSnackbar(message);
      this.canDeactivateForm = true;
      this.closeForm(true);
    }).finally(() => this.isLoading = false);
  }

  private closeForm(omitConfirmation: boolean, preventBackAction: boolean = false): void {
    if (!this.isDialog) {
      const link = this.afterCloseRouterLink ? this.afterCloseRouterLink : '/more-games/list';
      this.router.navigate([link]);
    } else {
      this.closeDialog(omitConfirmation, preventBackAction);
    }
  }

  private closeDialog(omitConfirmation: boolean, preventBackAction: boolean = false): void {
    if (preventBackAction) {
      this.preventBackActionDialogOpen();
    }
    const omit = this.showFullForm ? omitConfirmation : true;
    this.closeDialogSubject.next(omit);
  }

  private preventBackActionDialogOpen(): void {
    if (this.backAction) {
      this.backAction.backActionDialogConfig = null;
    }
  }

  public onPromotedProductsCampaignsChange(productCampaign: IPromotedProductCampaignMG[]): void {
    const currentLocation = this.formGroup.get('location').value;
    if (currentLocation) {
      getProductsCampaignsControl(this.formGroup, currentLocation.name as Locations).setValue(productCampaign);
    }
  }

  /* Steps */
  public onGoToStepClicked(data: { step: MoreGamesFormSteps, location?: Locations }): void {
    if (!this.showFullForm) {
      this.router.navigate(['/more-games/edit', this.initialValue.id], {
        state: {
          stepName: data.step,
          location: data.location,
          afterCloseLink: this.router.url
        }
      });
      this.closeForm(true, true);
    } else {
      const idx = this.steps.findIndex(step => step.name === data.step);
      this.goToStep(idx);
      if (location) {
        this.setLocation(data.location);
      }
    }
  }

  private goToStep(idx: number): void {
    this.activeStep = this.steps[idx];
    this.moreGamesFormService.updateStepsSubLabels(this.steps, this.formGroup);
  }

  public get activeStepIndex(): number {
    return this.steps.findIndex(step => step.name === this.activeStep.name);
  }

  /* Action buttons logic */
  public isFirstStep(): boolean {
    return this.activeStep?.name === this.steps[0]?.name;
  }

  public onPreviousStepClicked(): void {
    if (this.activeStep.name === MoreGamesFormSteps.CHOOSE_DESTINATIONS) {
      const currentLocation = this.formGroup.get('location').value;

      if (this.previousLocationExists(currentLocation.name as Locations)) {
        this.goToPreviousLocation(currentLocation.name as Locations);
        return;
      }
    }

    this.goToStep(this.activeStepIndex - 1);
  }

  private previousLocationExists(currentLocation: Locations): boolean {
    const locations = this.moreGamesFormService.getLocationNames();
    const currentLocationIdx = locations.findIndex(location => location === currentLocation);
    return locations[currentLocationIdx - 1] !== undefined;
  }

  private goToPreviousLocation(currentLocation: Locations): void {
    const locations = this.moreGamesFormService.getLocationNames();
    const currentLocationIdx = locations.findIndex(location => location === currentLocation);
    this.setLocation(locations[currentLocationIdx - 1]);
  }

  public onCancelClicked(): void {
    this.closeForm(false);
  }

  public isNextStepButtonVisible(): boolean {
    return this.activeStep?.name !== MoreGamesFormSteps.SELECT_SOURCE &&
      this.activeStep?.name !== this.steps[this.steps.length - 1]?.name && this.showFullForm;
  }

  public isStepValid(step: Tab = this.activeStep): boolean {
    return this.moreGamesFormService.isStepValid(step, this.formGroup);
  }

  public onNextStepClicked(): void {
    if (this.activeStep.name === MoreGamesFormSteps.CHOOSE_DESTINATIONS) {
      const currentLocation = this.formGroup.get('location').value;

      if (this.nextLocationExists(currentLocation.name as Locations)) {
        this.goToNextLocation(currentLocation.name as Locations);
        return;
      }

      const incompleteLocation: Locations = this.getIncompleteLocation();
      if (incompleteLocation) {
        this.translateService.get('moreGames.moreGamesForm.fillAllFieldsError').subscribe((message: string) => {
          this.snackbarService.openErrorSnackbar(message);
        });
        this.formGroup.get('location').setValue(this.moreGamesFormService.getLocation(incompleteLocation));
        return;
      }
    }

    const nextStepIndex = this.activeStepIndex + 1;
    this.goToStep(nextStepIndex);
  }

  private nextLocationExists(currentLocation: Locations): boolean {
    const locations = this.moreGamesFormService.getLocationNames();
    const currentLocationIdx = locations.findIndex(location => location === currentLocation);
    return locations[currentLocationIdx + 1] !== undefined;
  }

  private goToNextLocation(currentLocation: Locations): void {
    const locations = this.moreGamesFormService.getLocationNames();
    const currentLocationIdx = locations.findIndex(location => location === currentLocation);
    this.setLocation(locations[currentLocationIdx + 1]);
  }

  private setLocation(location: Locations): void {
    this.formGroup.get('location').setValue(this.moreGamesFormService.getLocation(location));
    this.currentLocation = location;
  }

  private getIncompleteLocation(): Locations {
    for (const location of this.moreGamesFormService.getLocationNames()) {
      const controls = getControlsForLocation(this.formGroup, location);
      const someControlInvalid = controls.some(control => control.invalid);
      if (someControlInvalid) {
        return location;
      }
    }
    return null;
  }

  public isSubmitButtonVisible(): boolean {
    return this.activeStep?.name === MoreGamesFormSteps.SUMMARY && !this.isEditMode;
  }

  public isSubmitEditButtonVisible(): boolean {
    return this.showFullForm && this.activeStep?.name === MoreGamesFormSteps.SUMMARY && this.isEditMode;
  }

  public isFormValid(): boolean {
    return this.formGroup.valid;
  }

  /* Selecting steps */
  public onStepSelected(step: Tab): void {
    if (!step.isDisabledFn(step)) {
      this.goToStep(this.formStepsHandler.getStepIdx(step));
    }
  }

  public isStepDisabled(step: Tab): boolean {
    return this.formStepsHandler.isStepDisabled(step);
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub?.unsubscribe());
  }
}

