<app-base-side-dialog [dialogRef]="dialogRef" [showBackButton]="!!backAction?.backActionDialogConfig"
                      [backAction]="backAction">
  <app-spinner *ngIf="isLoading"></app-spinner>

  <div class="preview-content" *ngIf="moreGames">
    <div class="text-light side-dialog--padding-horizontal preview-header">
      <p class="mat-body-2 color-dark-grey-fourth">{{"moreGames.moreGamesPreview.header" | translate}}</p>

      <div class="side-dialog-header-row">
        <div class="align-center-gap-10">
          <h1>{{moreGames.name}}</h1>
          <h2 class="margin-0">
          <a class="cursor-pointer" [href]="moreGames.generatedHTMLUrl" target="_blank">
            <fa-icon [appIconTooltip]="faArrowUpRightFromSquare" class="color-primary preview-icon" [icon]="faArrowUpRightFromSquare"></fa-icon>
          </a>
          </h2>
        </div>
      </div>
      <div class="side-dialog-header-row">
        <p>
          {{"moreGames.moreGamesPreview.source" | translate}}: {{moreGames.product.name}}
        </p>
        <div class="center-gap-5">
          <p>{{"moreGames.moreGamesPreview.platform" | translate}}: </p>
          {{moreGames.platform.type || '-'}}
        </div>
        <p>
          {{"moreGames.moreGamesPreview.regions" | translate}}
          : {{getRegionsNameShort(moreGames.regions).join(', ') || '-'}}
        </p>
      </div>
    </div>

    <hr class="separator separator--no-margin"/>
    <div class="side-dialog--padding-horizontal preview-body--scrollable">
      <ng-container
        *ngTemplateOutlet="destinationsTemplate; context: {
        campaigns: moreGames.topPanelCampaigns, destinations: moreGames.topPanelPromotedProducts, position: 'top'}">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="destinationsTemplate; context: {
        campaigns: moreGames.topVideoPanelCampaigns, destinations: moreGames.topVideoPanelPromotedProducts, position: 'top video'}">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="destinationsTemplate; context: {
        campaigns: moreGames.topPlayablePanelCampaigns, destinations: moreGames.topPlayablePanelPromotedProducts, position: 'top playable'}">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="destinationsTemplate; context: {
        campaigns: moreGames.middlePanelCampaigns, destinations: moreGames.middlePanelPromotedProducts, position: 'middle'}">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="destinationsTemplate; context: {
        campaigns: moreGames.middleVideoPanelCampaigns, destinations: moreGames.middleVideoPanelPromotedProducts, position: 'middle video'}">
      </ng-container>
      <ng-container
        *ngTemplateOutlet="destinationsTemplate; context: {
        campaigns: moreGames.bottomPanelCampaigns, destinations: moreGames.bottomPanelPromotedProducts, position: 'bottom'}">
      </ng-container>

      <hr style="margin-top: 20px;">
      <div class="side-dialog-action-buttons">
        <button (click)="onClose()" mat-stroked-button color="primary">
          <fa-icon [icon]="faXMark" class="close-icon-big"></fa-icon>
          {{"button.close" | translate}}
        </button>
        <button (click)="onDeleteMoreGamesClicked()" mat-flat-button color="primary">
          <fa-icon [icon]="faTrash"></fa-icon>
          {{"moreGames.deleteMoreGames" | translate}}
        </button>
        <button (click)="onEditMoreGamesClicked()" mat-flat-button color="primary">
          <fa-icon [icon]="faEdit"></fa-icon>
          {{"moreGames.editMoreGames" | translate}}
        </button>
      </div>
    </div>
  </div>
</app-base-side-dialog>


<ng-template let-position="position" let-destinations="destinations" let-campaigns="campaigns" #destinationsTemplate>
  <div class="padding-t-20 product-campaigns-list">
    <tr>
      <th class="text-16-txt-1-semi-bold mat-body">{{'moreGames.moreGamesPreview.destinations' | translate}} ({{position}})</th>
      <th class="text-16-txt-1-semi-bold mat-body">{{'moreGames.moreGamesPreview.campaigns' | translate}}</th>
    </tr>
    <ng-container *ngIf="destinationsDefined(destinations)">
      <tr *ngFor="let destination of destinations; let i = index">
        <td class="text-16-txt-1-light">
          <div class="table-cell-content table-cell-content--left">
          {{(i + 1) + '.'}} {{destination?.name || '-'}}
          <fa-icon *ngIf="destination?.id"
                   (click)="onProductPreviewClicked(destination?.id)"
                   class="color-primary cursor-pointer action-icon"
                   [appIconTooltip]="faEye" [icon]="faEye">
          </fa-icon>
        </div>
        </td>
        <td class="text-16-txt-1-light">
          <div class="table-cell-content">
          {{(i + 1) + '.'}}  {{campaigns[i]?.name || ('campaign.noCampaign' | translate)}}
          <fa-icon *ngIf="campaigns[i]?.id"
                   (click)="onCampaignPreviewClicked(campaigns[i]?.id)"
                   class="color-primary cursor-pointer action-icon"
                   [appIconTooltip]="faEye"
                   [icon]="faEye">
          </fa-icon>
          </div>
        </td>
      </tr>
    </ng-container>
    <tr *ngIf="!destinationsDefined(destinations)">
      <td class="text-16-txt-1-light">
        -
      </td>
      <td class="text-16-txt-1-light">
        -
      </td>
    </tr>
  </div>
</ng-template>
