<ng-container *ngIf="isDialog">
  <app-base-expanded-dialog [showConfirmPopup]="showFullForm" [backAction]="backAction" [closeDialogObservable]="closeDialogSubject.asObservable()">
    <ng-container *ngTemplateOutlet="moreGamesForm"></ng-container>
  </app-base-expanded-dialog>
</ng-container>

<ng-container *ngIf="!isDialog">
  <ng-container *ngTemplateOutlet="moreGamesForm"></ng-container>
</ng-container>


<ng-template #moreGamesForm>
  <div class="form-page">
    <div [ngClass]="isDialog ? 'no-padding-top' : ''" class="add-form-header">
      <h1 *ngIf="!isEditMode" [ngClass]="{'h1-semibold': !isDialog}">{{'moreGames.moreGamesForm.newMoreGamesFormHeader' | translate}}</h1>
      <h1 *ngIf="isEditMode">{{'moreGames.moreGamesForm.editMoreGamesFormHeader' | translate}}</h1>
      <p class="translate-wrapper--strong" *ngIf="isEditMode && formGroup" [innerHTML]="'moreGames.moreGamesForm.editMoreGamesFormHeaderProductInfo' |
      translate: {name: this.formGroup.value.sourceProduct?.name}" ></p>
    </div>
    <app-spinner *ngIf="isFormLoading"></app-spinner>
    <ng-container *ngIf="formGroup">
      <div [hidden]="!showFullForm"  class="form-page-content">
        <div class="d-flex flex-column h-100 overflow-y-hidden">
          <div [ngClass]="{'tabs-wrapper--background': !isDialog}">
            <app-steps (stepChanged)="onStepSelected($event)" [activeStep]="activeStep" [steps]="steps"></app-steps>
          </div>
          <hr class="margin-0">
          <div class="flex-grow-1 overflow-y-auto">
            <form [formGroup]="formGroup" class="h-100">
              <app-source-select *ngIf="!isEditMode" [label]="'moreGames.moreGamesForm.tabs.selectSource' | translate"
                                            [selectedProduct]="formGroup?.value?.sourceProduct"
                                            [hidden]="!(activeStep?.name === MoreGamesFormSteps.SELECT_SOURCE)"
                                            (productSelected)="onSourceProductSelected($event)"></app-source-select>
              <app-more-games-info-form
                [initialValue]="initialValue"
                [hidden]="!(activeStep?.name === MoreGamesFormSteps.CREATE_MORE_GAMES)">
              </app-more-games-info-form>
              <app-more-games-choose-products
                [currentLocation]="currentLocation"
                [initialValue]="initialValue"
                [selectedProduct]="formGroup.value.sourceProduct"
                [refreshProductsCampaigns]="productsCampaignsRefresh"
                (productsCampaignsChanged)="onPromotedProductsCampaignsChange($event)"
                [hidden]="!(activeStep?.name === MoreGamesFormSteps.CHOOSE_DESTINATIONS)">
              </app-more-games-choose-products>
              <app-more-games-summary
              [hidden]="!(activeStep?.name === MoreGamesFormSteps.SUMMARY)"
              (goToStepClicked)="onGoToStepClicked($event)"
              [isEdited]="isEditMode"
              ></app-more-games-summary>
            </form>
          </div>
        </div>
      </div>
      <div [hidden]="showFullForm" class="form-page-content">
        <form [formGroup]="formGroup">
          <app-more-games-summary
            [isInlineEditActive]="false"
            [showHeading]="false"
            [isEdited]="true"
            (goToStepClicked)="onGoToStepClicked($event)"
          ></app-more-games-summary>
        </form>
      </div>
      <div *ngIf="showFullForm" class="add-form-action-buttons add-form-action-buttons--background">
        <ng-container *ngIf="isEditMode">
          <button (click)="onCancelClicked()" mat-stroked-button color="primary">
            {{'button.cancel' | translate}}
          </button>
          <button *ngIf="!isFirstStep()" (click)="onPreviousStepClicked()" mat-stroked-button color="primary">
            <fa-icon [icon]="faArrowLeft"></fa-icon>
            {{'button.previousStep' | translate}}
          </button>
          <button *ngIf="isNextStepButtonVisible()" [disabled]="!isStepValid()" (click)="onNextStepClicked()" mat-stroked-button
                  color="primary">
            <fa-icon [icon]="faFloppyDiskCircleArrowRight"></fa-icon>
            {{'button.saveAndNextStep' | translate}}
          </button>
          <button [disabled]="!isFormValid()" (click)="onSubmitEditClicked()" mat-flat-button
                  color="primary">
            <fa-icon [icon]="faFloppyDiskCircleArrowRight"></fa-icon>
            {{'button.finishAndSave' | translate}}
          </button>
        </ng-container>

        <ng-container *ngIf="!isEditMode">
          <button (click)="onCancelClicked()" mat-stroked-button color="primary">
            {{'button.cancel' | translate}}
          </button>
          <button *ngIf="!isFirstStep()" (click)="onPreviousStepClicked()" mat-stroked-button color="primary">
            <fa-icon [icon]="faArrowLeft"></fa-icon>
            {{'button.previousStep' | translate}}
          </button>
          <button *ngIf="isNextStepButtonVisible()" [disabled]="!isStepValid()" (click)="onNextStepClicked()" mat-flat-button
                  color="primary">
            <fa-icon [icon]="faFloppyDiskCircleArrowRight"></fa-icon>
            {{'button.saveAndNextStep' | translate}}
          </button>
          <button *ngIf="isSubmitButtonVisible()" [disabled]="!isFormValid()" (click)="onSubmitClicked()" mat-flat-button
                  color="primary">
            <fa-icon [icon]="faFloppyDiskCircleArrowRight"></fa-icon>
            {{'moreGames.moreGamesForm.saveMoreGames' | translate}}
          </button>
        </ng-container>

        <mat-spinner *ngIf="isLoading" [diameter]="30"></mat-spinner>
      </div>
    </ng-container>
  </div>
</ng-template>
