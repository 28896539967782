import {Injectable} from '@angular/core';
import {IDialogConfig} from '../../shared/interface/ui/dialog/IDialogConfig';
import {DialogType} from '../../shared/enum/ui/dialog/Dialog';
import {ModuleImporterService} from '../../shared/service/module-importer/module-importer.service';
import {DialogService} from '../../shared/service/dialog/dialog/dialog.service';
import {MoreGamesPreviewComponent} from '../component/more-games-preview/more-games-preview.component';
import {DialogRef} from '../../shared/service/dialog/dialog/dialog-ref';
import {TranslationPrefix} from '../../shared/enum/ui/dialog/TranslationPrefix';
import {ConfirmDialogService} from '../../shared/service/dialog/confirm-dialog.service';
import {MoreGamesService} from './more-games.service';
import { TemplatePreviewComponent } from '../component/more-games-info-form/template-preview/template-preview.component';
import { MoreGamesFormComponent } from '../component/more-games-form/more-games-form.component';
import { ExpandedDialogService } from 'src/app/shared/service/dialog/expanded-dialog/expanded-dialog.service';
import {MoreGamesBatchDeleteComponent} from '../component/more-games-batch-delete/more-games-batch-delete.component';
import {IConfirmDialogData} from '../../shared/interface/ui/dialog/IConfirmDialogData';
import {firstValueFrom} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ConfirmDialogComponent} from '../../shared/component/common/confirm-dialog/confirm-dialog.component';

/**
 * Service for managing dialogs related to More Games functionality.
 * Provides methods for opening various types of dialogs such as preview, delete, edit, and template preview dialogs.
 */
@Injectable({
  providedIn: 'root'
})
export class MoreGamesDialogService {

  /**
   * Constructor for MoreGamesDialogService.
   * @param importerService Instance of ModuleImporterService for dynamically importing modules.
   * @param dialogService Instance of DialogService for managing dialogs.
   * @param confirmDialogService Instance of ConfirmDialogService for displaying confirmation dialogs.
   * @param moreGamesService Instance of MoreGamesService for managing More Games data.
   */
  constructor(private importerService: ModuleImporterService, private dialogService: DialogService,
              private confirmDialogService: ConfirmDialogService, private moreGamesService: MoreGamesService,
              private expandedDialogService: ExpandedDialogService, private translateService: TranslateService) {
  }

  /**
   * Opens a dialog to preview More Games.
   * @param moreGamesId The ID of the More Games entry to preview.
   * @param backActionDialog Optional configuration for the back action in the dialog.
   * @returns A promise that resolves when the dialog is opened.
   */
  public async openMoreGamesPreviewDialog(moreGamesId: string, backActionDialog?: IDialogConfig): Promise<void> {
    await this.importerService.importMoreGamesModule();
    this.dialogService.open(MoreGamesPreviewComponent, {type: DialogType.side, data: {moreGamesId}, backActionDialog});
  }

  /**
   * Opens a dialog to delete a More Games entry.
   * @param name The name of the More Games entry to be deleted.
   * @param id The ID of the More Games entry to be deleted.
   * @returns A promise resolving to a DialogRef representing the opened dialog.
   */
  public async openMoreGamesDeleteDialog(name: string, id: string): Promise<DialogRef> {
    await this.importerService.importMoreGamesModule();
    const dialogRef: DialogRef = await this.confirmDialogService
      .openDeleteDialog({objectName: TranslationPrefix.MORE_GAMES, data: {name}});
    dialogRef.afterClosed().subscribe(async (value) => {
      if (value) {
        await this.moreGamesService.removeMoreGames(id);
      }
    });
    return dialogRef;
  }

  /**
   * Opens a dialog to edit a More Games entry.
   * @param moreGamesId The ID of the More Games entry to edit.
   * @param backActionDialog Optional configuration for the back action in the dialog.
   * @returns A promise that resolves when the dialog is opened.
   */
  public async openMoreGamesEditDialog(moreGamesId: string, backActionDialog?: IDialogConfig): Promise<void> {
    await this.importerService.importMoreGamesModule();
    const formComponent = this.expandedDialogService.open(MoreGamesFormComponent, {
      backActionDialog,
      type: DialogType.expanded
    });
    formComponent.instance.isEditMode = true;
    formComponent.instance.initialMoreGamesId = moreGamesId;
    formComponent.instance.isDialog = true;
  }

  /**
   * Opens a dialog to preview a More Games template.
   * @param url The URL of the template to preview.
   * @returns A promise that resolves when the dialog is opened.
   */
  public async openMoreGamesTemplateDialog(url: string) {
    await this.importerService.importMoreGamesModule();
    this.dialogService.open(TemplatePreviewComponent, {
      type: DialogType.center,
      data: { url }
    });
  }

  public openMoreGamesDeleteCsv(): DialogRef {
    return this.dialogService.open(MoreGamesBatchDeleteComponent, {type: DialogType.center});
  }

  public async openImportErrorsConfirmDialog(): Promise<DialogRef> {
    const data: IConfirmDialogData = {
      header: await firstValueFrom(this.translateService.get('import.acceptWarningsHeader')),
      text: await firstValueFrom(this.translateService.get('import.acceptWarningsMessage')),
      acceptButton: {
        name: await firstValueFrom(this.translateService.get('button.save')),
      },
      closeButton: {
        name: await firstValueFrom(this.translateService.get('button.cancel'))
      }
    };
    return this.dialogService.open(ConfirmDialogComponent, {type: DialogType.center, data});
  }
}
